import React, { useState, useEffect, useRef, Children } from 'react';
import { Trans } from "@lingui/macro";

export function PullToRefresh({ onRefresh, children }) {
  const [pulling, setPulling] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [pullDistance, setPullDistance] = useState(0);
  const startTouchY = useRef(null);
  const contentRef = useRef(null);

  const onTouchStart = (e) => {
    if (contentRef.current.scrollTop === 0) {
      startTouchY.current = e.touches[0].pageY;
    }
  };

  const onTouchMove = (e) => {
    if (startTouchY.current !== null) {
      const currentTouchY = e.touches[0].pageY;
      const distanceMoved = currentTouchY - startTouchY.current;
      if (distanceMoved > 0) {
        setPulling(true);
        setPullDistance(distanceMoved);
      }
    }
  };

  const onTouchEnd = () => {
    if (pulling && pullDistance > 150) {  // 设置阈值为150px
      setRefreshing(true);
      onRefresh().then(() => {
        setRefreshing(false);
      });
    }
    setPulling(false);
    setPullDistance(0);
    startTouchY.current = null;
  };

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      element.addEventListener('touchstart', onTouchStart);
      element.addEventListener('touchmove', onTouchMove);
      element.addEventListener('touchend', onTouchEnd);

      return () => {
        element.removeEventListener('touchstart', onTouchStart);
        element.removeEventListener('touchmove', onTouchMove);
        element.removeEventListener('touchend', onTouchEnd);
      };
    }
  }, [pulling, pullDistance, refreshing]);  // 添加依赖以防止多余的事件绑定

  return (
    <div ref={contentRef} style={{ overflowY: 'auto'}}>
      <div style={{
        marginTop:refreshing?'0px':'-32px',
        transition: 'all 0.3s',
        transform: `translateY(${pulling ? pullDistance / 2 : 0}px)`  // 拉动距离的一半用于平滑动画
      }}>
        {refreshing ? <div className='pull-to-refresh'><Trans>Refreshing...</Trans></div> : <div className='pull-to-refresh'><Trans>Pull to refresh</Trans></div>}
        {/* 这里可以放置你的内容 */}
        {children}
      </div>
    </div>
  );
}
