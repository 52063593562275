import "@rainbow-me/rainbowkit/styles.css";
import { ethers } from "ethers";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  safeWallet,
  walletConnectWallet,
  tokenPocketWallet,
} from "@rainbow-me/rainbowkit/wallets";
import React from "react";
import { Environment } from "src/helpers/environment/Environment/Environment";
import { configureChains, createConfig, type WalletClient, useWalletClient, sepolia, mainnet } from "wagmi";
import { bsc, bscTestnet, polygon } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  // [sepolia],
  [polygon],
  // [mainnet],
  // [mainnet, sepolia],
  // [bscTestnet],
  // [bsc, bscTestnet],
[
    jsonRpcProvider({
        rpc: (chain) => {
          if (chain.id !== polygon.id) return null;
          return {
            http: process.env.REACT_APP_RPC_URL||"",
          };
        },
      }),
    // jsonRpcProvider({ rpc: chain => ({ http: chain.rpcUrls.default.http[0] }) }),
    // alchemyProvider({ apiKey: process.env.REACT_PUBLIC_ALCHEMY_API_KEY as string }),
    // publicProvider(),

  ],
);

const needsInjectedWalletFallback =
  typeof window !== "undefined" && window.ethereum && !window.ethereum.isMetaMask && !window.ethereum.isCoinbaseWallet;

const walletConnectProjectId = Environment.getWalletConnectProjectId() as string;

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      tokenPocketWallet({ projectId: walletConnectProjectId, chains }),
      metaMaskWallet({ projectId: walletConnectProjectId, chains, shimDisconnect: true }),
      braveWallet({ chains, shimDisconnect: true }),
      rainbowWallet({ projectId: walletConnectProjectId, chains }),
      okxWallet({ projectId: walletConnectProjectId, chains }),
      walletConnectWallet({ projectId: walletConnectProjectId, chains }),
      coinbaseWallet({ appName: "Origin", chains }),
      rabbyWallet({ chains, shimDisconnect: true }),
      safeWallet({ chains }),
      ...(needsInjectedWalletFallback ? [injectedWallet({ chains, shimDisconnect: true })] : []),
    ],
  },
]);

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new ethers.providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });
  return React.useMemo(() => (walletClient ? walletClientToSigner(walletClient) : undefined), [walletClient]);
}

export const wagmiClient = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
