import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { useState, MouseEvent, useEffect } from "react";
import { Popper, Button, Paper, Typography, Box, Fade, SvgIcon, Link } from "@mui/material";
import { useIsPath } from "../../hooks";
import { useAppSelector } from "src/hooks";

import FlagIcon from "../../helpers/flagicon.js";
import { locales, selectLocale } from "../../locales";
import { ReactComponent as ArrowDownIcon } from "src/assets/icons/arrow-down.svg";

function getLocaleFlag(locale) {
  return locales[locale].flag;
}

function SelectTime({ activeReward, handleSelectSequence, currentTitle, rewardType }) {
  const id = "locales-popper";
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeSequence, setActiveSequence] = useState([]);
  const open = Boolean(anchorEl);
  const isHome = useIsPath("/home");
  const hourRoundSequence = useAppSelector(state => state.community.roundSequence?.Hour);
  const weekRoundSequence = useAppSelector(state => state.community.roundSequence?.Week);
  const monthRoundSequence = useAppSelector(state => state.community.roundSequence?.Month);
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    console.log("active ", hourRoundSequence);
    setActiveSequence(
      activeReward == 0 ? hourRoundSequence : activeReward == 1 ? weekRoundSequence : monthRoundSequence,
    );
  }, [hourRoundSequence, weekRoundSequence, monthRoundSequence, activeReward]);

  return (
    <Box
      component="div"
      onMouseEnter={e => handleClick(e)}
      onMouseLeave={e => handleClick(e)}
      id="locales-menu-button-hover"
      sx={{}}
      display="flex"
      alignItems="center"
      justifyContent={"center"}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        sx={{
          mr: 2,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          sx={{
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              color: "#B50C05",
              fontSize: "13px",
              textTransform: "uppercase",
              mr: 1,
            }}
          >
            {currentTitle}
          </Typography>
          <SvgIcon
            viewBox="0 0 15 8"
            sx={{
              width: "15px",
            }}
            component={ArrowDownIcon}
            htmlColor="#B50c05"
          />
        </Box>

        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition sx={{ zIndex: "1112" }}>
          {/* <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom" transition sx={{ zIndex: "1112" }}> */}
          {({ TransitionProps }) => {
            return (
              <Fade {...TransitionProps} timeout={100}>
                <Paper
                  className="header-menu"
                  elevation={1}
                  sx={{
                    borderRadius: "8px",
                    border: "2.4px solid rgba(28, 30, 33, 0.60)",
                    background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                    boxShadow: "2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      borderRadius: "8px",
                      background: "linear-gradient(138deg, #32373A 2.23%, #131416 97.6%)",
                      boxShadow: "0px 0px 4px 0px #33383C inset",
                    }}
                  >
                    {activeSequence.map((item, index) => (
                      <Box
                        sx={{ px: 1.675, py: 1, cursor: "pointer" }}
                        key={index}
                        onClick={() => handleSelectSequence(item)}
                      >
                        <Typography
                          sx={{
                            color: isHome ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          {`${rewardType} ${item + 1}`}
                          {/* {item + 1} */}
                          <Trans>roundSequence</Trans>
                        </Typography>
                      </Box>
                    ))}
                    {/* <Box sx={{ px: 1.675, py: 1, cursor: "pointer" }}>
                      <Typography
                        sx={{
                          color: isHome ? "#B50C05" : "#c7c8cc",
                          textShadow: "0px 1px 0px #000",
                          fontFamily: "Neue Haas Grotesk Text Pro",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                      >
                        2021/01/02 10:00
                      </Typography>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1, cursor: "pointer" }}>
                      <Typography
                        sx={{
                          color: isHome ? "#B50C05" : "#c7c8cc",
                          textShadow: "0px 1px 0px #000",
                          fontFamily: "Neue Haas Grotesk Text Pro",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                      >
                        2021/01/02 10:00
                      </Typography>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1, cursor: "pointer" }}>
                      <Typography
                        sx={{
                          color: isHome ? "#B50C05" : "#c7c8cc",
                          textShadow: "0px 1px 0px #000",
                          fontFamily: "Neue Haas Grotesk Text Pro",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                      >
                        2021/01/02 10:00
                      </Typography>
                    </Box> */}
                  </Box>
                </Paper>
              </Fade>
            );
          }}
        </Popper>
      </Box>
    </Box>
  );
}

export default SelectTime;
