import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { BondDataCard, BondTableData } from "./BondRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatCurrency } from "../../helpers";
import useBonds from "../../hooks/Bonds";
import { DEFAULT_PROVIDER, useWeb3Context } from "src/hooks/web3Context";
import { useState } from "react";
import "./choosebond.scss";
import { Skeleton } from "@mui/material";
import ClaimBonds from "./ClaimBonds";
import isEmpty from "lodash/isEmpty";
import { allBondsMap } from "src/helpers/AllBonds";
import { useAppSelector } from "src/hooks";
import { IUserBondDetails } from "src/slices/AccountSlice";
import { useMobile } from "../../hooks";
import { BondHistory } from "../Bond/BondHistory";
import { PullToRefresh } from "src/components/PullToRefresh";
import { useDispatch } from "react-redux";
import { loadAppDetails } from "src/slices/AppSlice";
import { ethers } from "ethers";
import { calcBondDetails } from "src/slices/BondSlice";


function ChooseBond() {
  const { isSmallScreen } = useMobile();
  const dispatch = useDispatch();
  const { chainID, address } = useWeb3Context();
  const { bonds } = useBonds(chainID);
  // const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDirectBondPage, setCurrentDirectBondPage] = useState(1);
  const isAppLoading: boolean = useAppSelector(state => state.app.loading);
  const isAccountLoading: boolean = useAppSelector(state => state.account.loading);
  // console.log("bonds", bonds);
  const accountBonds: IUserBondDetails[] | any[] = useAppSelector(state => {
    let withInterestDue: any[] = [];
    for (const bond in state.account.bonds) {
      if (Array.isArray(state.account.bonds[bond])) {
        let _bonds = state.account.bonds[bond];
        _bonds = (_bonds as any).filter(
          (_bond: { id: any; interestDue: any }) => _bond.id && Number(_bond.id) >= 0 && Number(_bond.interestDue) > 0,
        );
        withInterestDue = [...withInterestDue, ...(_bonds as unknown as any[])];
      } else if (Number((state.account.bonds[bond] as IUserBondDetails).interestDue) > 0) {
        withInterestDue.push(state.account.bonds[bond]);
      }
    }
    withInterestDue = withInterestDue.sort((a, b) => {
      return b.pendingPayout - a.pendingPayout;
    });
    return withInterestDue;
  });

  const accountDirectBonds: IUserBondDetails[] | any[] = useAppSelector(state => {
    let withInterestDue: any[] = [];
    for (const bond in state.account.directBonds) {
      if (Array.isArray(state.account.directBonds[bond])) {
        let _bonds = state.account.directBonds[bond];
        _bonds = (_bonds as any).filter(
          (_bond: { interestDue: any }) => _bond.interestDue && Number(_bond.interestDue) > 0,
        );
        // console.log("state.account.bonds _bonds:", _bonds);
        withInterestDue = [...withInterestDue, ...(_bonds as unknown as any[])];
      } else if ((state.account.directBonds[bond] as IUserBondDetails).interestDue > 0) {
        withInterestDue.push(state.account.directBonds[bond]);
      }
    }

    withInterestDue = withInterestDue.sort((a, b) => {
      return b.pendingPayout - a.pendingPayout;
    });
    return withInterestDue;
  });

  const marketPrice: number | undefined = useAppSelector(state => {
    return state.app.marketPrice;
  });

  const treasuryBalance: number | undefined = useAppSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      return tokenBalances;
    }
  });

  const changePage = (event: any, page: any) => {
    // console.log(event, page);
    setCurrentPage(page);
  };

  const directBondChangePage = (event: any, page: any) => {
    // console.log(event, page);
    setCurrentDirectBondPage(page);
  };

  const currentPageBonds = accountBonds.slice((currentPage - 1) * 5, currentPage * 5);
  const pageCount = Math.ceil(accountBonds.length / 5);
  const currentPageDirectBonds = accountDirectBonds.slice((currentPage - 1) * 5, currentPage * 5);
  const directBondsPageCount = Math.ceil(accountDirectBonds.length / 5);

  
  return (
    <PullToRefresh onRefresh={async ()=>{
      const defaultProvider  = DEFAULT_PROVIDER;
      const works = [];
      works.push(dispatch(loadAppDetails({ networkID: chainID, provider: defaultProvider })))

      bonds.map(bond => {
        console.log("bond app", chainID);
        return dispatch(calcBondDetails({ bond, value: "", provider: defaultProvider, networkID: chainID }));
      });

      return Promise.all(works);
    }}>

    
    <div id="choose-bond-view">
      <Zoom in={true}>
        <Box
          sx={{
            px: isSmallScreen ? 1.875 : 0,
            pt: isSmallScreen ? 2.5 : 0,
            width: "100%",
          }}
          display="flex"
          justifyContent={"center"}
        >
          <Paper
            className="ohm-card linear-card"
            sx={{
              mb: 5,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: isSmallScreen ? "12px" : "24px",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                px: isSmallScreen ? 2.5 : 3.75,
                py: isSmallScreen ? 1.875 : 2.5,
                pb: isSmallScreen ? 0 : 2.5,
              }}
            >
              <Box className="card-header">
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "21px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  <Trans>Bond</Trans> (1,1)
                </Typography>
              </Box>

              <Grid container item xs={12} sx={{ my: isSmallScreen ? 3.75 : 2.5 }} className="bond-hero">
                <Grid item xs={12} sm={6}>
                  <Box
                    textAlign={`${isVerySmallScreen ? "left" : "center"}`}
                    sx={{
                      mb: isSmallScreen ? 2 : 0,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#8C8C99",
                        fontFamily: "AGaramond LT",
                        fontSize: isSmallScreen ? "14px" : "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="center"
                    >
                      <Trans>Treasury Balance</Trans>
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontSize: isSmallScreen ? "21px" : "27px",
                          fontWeight: "700",
                          fontFamily: "AGaramond LT",
                        }}
                        data-testid="treasury-balance"
                      >
                        {isAppLoading ? (
                          <Skeleton width="180px" data-testid="treasury-balance-loading" />
                        ) : treasuryBalance == 0 ? (
                          "--"
                        ) : (
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          }).format(Number(treasuryBalance))
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} className={`ohm-price`}>
                  <Box textAlign={`${isVerySmallScreen ? "right" : "center"}`}>
                    <Typography
                      sx={{
                        color: "#8C8C99",
                        fontFamily: "AGaramond LT",
                        fontSize: isSmallScreen ? "14px" : "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="center"
                    >
                      <Trans>LGNS Price</Trans>
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontSize: isSmallScreen ? "21px" : "27px",
                          fontWeight: "700",
                          fontFamily: "AGaramond LT",
                        }}
                      >
                        {isAppLoading ? (
                          <Skeleton width="100px" />
                        ) : marketPrice == 0 ? (
                          "--"
                        ) : (
                          formatCurrency(Number(marketPrice), 4)
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {!isSmallScreen ? (
                <Grid container item>
                  <TableContainer>
                    <Table aria-label="Available bonds">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#868B8F",
                              fontFamily: "AGaramond LT",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Bond</Trans>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: "#868B8F",
                              fontFamily: "AGaramond LT",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Price</Trans>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: "#868B8F",
                              fontFamily: "AGaramond LT",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>ROI</Trans>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              color: "#868B8F",
                              fontFamily: "AGaramond LT",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Purchased</Trans>
                          </TableCell>
                          <TableCell align="right" sx={{ borderBottom: "none" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bonds.map(bond => (
                          bond.name!='eth'&&bond.name!='btc'?
                          <BondTableData key={bond.name} bond={bond} />:null
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : (
                <Box className="ohm-card-container">
                  <Grid container item spacing={2}>
                    {bonds.filter((bond,index)=>bond.name!='eth'&&bond.name!='btc').map((bond, index) => (
                      bond.name!='eth'&&bond.name!='btc'?
                      <Grid item xs={12} key={bond.name}>
                        {index == 1 && (
                          <Box sx={{ mb: "20px" }}>
                            <Typography
                              sx={{
                                color: "#B50C05",
                                fontFamily: "AGaramond LT",
                                fontSize: "22px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                              }}
                            >
                              <Trans>Treasury Bond</Trans>
                            </Typography>
                          </Box>
                        )}
                        <BondDataCard key={bond.name} bond={bond} />
                        {index < bonds.length - 1 && (
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              background: "#0b0c0a",
                              marginBottom: "10px",
                              boxShadow: "0px -1px 0px 0px rgba(83, 89, 102, 0.60)",
                            }}
                          />
                        )}
                      </Grid>:null
                    ))}

                    {/* {isSmallScreen && (
        <Box className="ohm-card-container">
          <Grid container item spacing={2}>
            {bonds.map(bond => (
              <Grid item xs={12} key={bond.name}>
                <BondDataCard key={bond.name} bond={bond} />
              </Grid>
            ))} */}
                  </Grid>
                </Box>
              )}
            </Box>

          </Paper>
        </Box>
      </Zoom>

      {!isAccountLoading && (
        <ClaimBonds
          count={pageCount}
          activeBonds={currentPageBonds}
          directBonds={accountDirectBonds}
          changePage={changePage}
          directBondChangePage={directBondChangePage}
          directBondCount={currentDirectBondPage}
        />
      )}
      <BondHistory address={ address } />
    </div>
    </PullToRefresh>
  );
}

export default ChooseBond;
