import Social from "./Social";
import { AppBar, Toolbar, Box, Button, SvgIcon, Link, Typography, Paper } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./footer.scss";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { Trans } from "@lingui/macro";
import { useIsPath } from "../../hooks";
import { useMobile } from "../../hooks";
function Footer({ handleScrollTop }) {
  const location = useLocation();
  const { isSmallScreen } = useMobile();

  return (
    <Paper
      className={"linear-card"}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: isSmallScreen ? "14px 14px 0 0 !important" : "26px 26px 0 0  !important",
      }}
    >
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          // stroke-width: 2px;
          // stroke: #434952;
          // border: "2px solid #434952",
          borderRadius: isSmallScreen ? "12px 12px 0 0" : "24px 24px 0 0 ",
          boxShadow: "14px 14px 100px rgba(0, 0, 0, 0.40)",
          height: "auto",
          // height: isSmallerScreen ? "auto" : "500px",
          // width: "100%",
        }}
        // style={{ margin: isSmallerScreen ? "20px" : "30px 100px" }}
      >
        <Box
          sx={{ maxWidth: "1220px", width: "100%", pt: 3.75, px: 1.875, mb: isSmallScreen ? 0 : 5 }}
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box sx={{ maxWidth: "505px", width: "100%", mr: isSmallScreen ? 0 : 10, mb: isSmallScreen ? 3.375 : 0 }}>
            <Box sx={{ mb: isSmallScreen ? 1.875 : 4 }}>
              <LogoIcon />
            </Box>
            <Typography
              sx={{
                color: "#868B8F",
                fontFamily: "Neue Haas Grotesk Text Pro",
                fontSize: isSmallScreen ? "14px" : "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: isSmallScreen ? "22px" : "28px",
              }}
            >
              <Trans>
                The global integrated financial autonomous system based on the algorithmic stablecoin A is a new concept
                that combines blockchain technology with traditional financial systems. In this system, the algorithmic
                stablecoin A serves as a bridge connecting the entire financial ecosystem, providing a foundational
                infrastructure and more innovative possibilities for numerous financial businesses.
              </Trans>
            </Typography>
          </Box>
          <Box sx={{ mr: isSmallScreen ? 0 : 10, mb: isSmallScreen ? 3.375 : 0 }}>
            <Box sx={{ mb: 4 }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              >
                <Trans>Navigation</Trans>
              </Typography>
            </Box>
            <Box display="flex">
              <Box sx={{ mr: 5 }}>
                <Link href="/" component={"div"} onClick={handleScrollTop}>
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Home</Trans>
                    </Typography>
                  </Box>
                </Link>
                <Link href="/dashboard">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Dashboard</Trans>
                    </Typography>
                  </Box>
                </Link>
                <Link href="/bonds">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Bond</Trans>
                    </Typography>
                  </Box>
                </Link>
                <Link href="/stake">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Stake</Trans>
                    </Typography>
                  </Box>
                </Link>
                <Link href="/reward">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Reward</Trans>
                    </Typography>
                  </Box>
                </Link>
              </Box>
              <Box sx={{ mr: 3 }}>
                <Link href="/community">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Community</Trans>
                    </Typography>
                  </Box>
                </Link>
                <Link href="/invite">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Invite</Trans>
                    </Typography>
                  </Box>
                </Link>
                <Link href="/turbine">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Turbine</Trans>
                    </Typography>
                  </Box>
                </Link>
                {/* <Link href="/docs">
                  <Box sx={{ py: 1.25 }}>
                    <Typography
                      sx={{
                        color: "#868B8F",
                        fontFamily: "Neue Haas Grotesk Text Pro",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#FB342B",
                        },
                      }}
                    >
                      <Trans>Docs</Trans>
                    </Typography>
                  </Box>
                </Link> */}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", maxWidth: "280px", mb: isSmallScreen ? 3.375 : 0 }}>
            {/* <Link href="https://originworld.org" target="_blank"> */}
            {!isSmallScreen&&<Box sx={{ mb: 4 }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              >
                <Trans>Social</Trans>
              </Typography>
            </Box>}
            {/* </Link> */}
            <Social />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: "1220px",
            height: "1px",
            background: "#1A1C1F",
            boxShadow: "0px 1px 0px 0px rgba(83, 89, 102, 0.60)",
          }}
        />
        <Box
          sx={{
            maxWidth: "1220px",
            width: "100%",
            py: 2.5,
          }}
        >
          <Typography
            align="center"
            sx={{
              color: "#868B8F",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "16px" /* 133.333% */,
              letterSpacing: "-0.24px",
            }}
          >
            Copyright © 2024 OriginWorld.org. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default Footer;
