import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import fonts from "./fonts";
import commonSettings, { handleBackdropFilter } from "./global.js";

// TODO: Break repeated use color values out into list of consts declared here
// then set the values in darkTheme using the global color variables

const darkTheme = {
  color: "#FCFCFC",
  gold: "rgba(0,166,100,1)",
  gray: "#A3A3A3",
  // textHighlightColor: "#F4D092",
  textHighlightColor: "rgba(0,166,100,1)",
  backgroundColor: "rgba(8, 15, 53, 1)",
  background: "#000809",
  // background: `
  //   linear-gradient(180deg, rgba(8, 15, 53, 0), rgba(0, 0, 10, 0.9)),
  //   linear-gradient(333deg, rgba(153, 207, 255, 0.2), rgba(180, 255, 217, 0.08)),
  //   radial-gradient(circle at 77% 89%, rgba(125, 163, 169, 0.8), rgba(125, 163, 169, 0) 50%),
  //   radial-gradient(circle at 15% 95%, rgba(125, 163, 169, 0.8), rgba(125, 163, 169, 0) 43%),
  //   radial-gradient(circle at 65% 23%, rgba(137, 151, 119, 0.4), rgba(137, 151, 119, 0) 70%),
  //   radial-gradient(circle at 10% 0%, rgba(187, 211, 204, 0.33), rgba(187,211,204,0) 35%),
  //   radial-gradient(circle at 11% 100%, rgba(131, 165, 203, 0.3), rgba(131, 165, 203, 0) 30%)
  //   `,
  paperBg: "rgba(54, 56, 64, 0.4)",
  paperBgColor: "linear-gradient(98.17deg, rgba(252, 252, 252, 0.08) 0%, rgba(95, 95, 95, 0.08) 100%)",
  drawerBg: "linear-gradient(180deg, #031A14 0%, #000809 100%)",
  modalBg: "linear-gradient(98.17deg, rgba(252, 252, 252, 0.08) 0%, rgba(95, 95, 95, 0.08) 100%)",
  // modalBg: "#24242699",
  popoverBg: "rgba(54, 56, 64, 0.99)",
  menuBg: handleBackdropFilter("rgba(54, 56, 64, 0.5)"),
  backdropBg: "rgba(54, 56, 64, 0.5)",
  largeTextColor: "#F4D092",
  activeLinkColor: "#F5DDB4",
  activeLinkSvgColor:
    "brightness(0) saturate(100%) invert(84%) sepia(49%) saturate(307%) hue-rotate(326deg) brightness(106%) contrast(92%)",
  primaryButtonColor: "#333333",
  primaryButtonBG: "rgba(0,166,100,1)",
  primaryButtonHoverBG: "rgba(4,143,87,1)",
  // primaryButtonBG: "#F4D092",
  // primaryButtonHoverBG: "#EDD8B4",
  secondaryButtonHoverBG: "rgba(54, 56, 64, 1)",
  outlinedPrimaryButtonHoverBG: "#000",
  // outlinedPrimaryButtonHoverBG: "#F8CC82",
  outlinedPrimaryButtonHoverColor: "#fff",
  outlinedSecondaryButtonHoverBG: "transparent",
  outlinedSecondaryButtonHoverColor: "#fff", //gold
  containedSecondaryButtonHoverBG: "rgba(255, 255, 255, 0.15)",
  graphStrokeColor: "rgba(255, 255, 255, .1)",
  idoBorder: "rgba(252, 252, 252, 0.12)",
  idoGridBorder: "rgba(252, 252, 252, 0.3)",
};

export const dark = responsiveFontSizes(
  createTheme(
    {
      primary: {
        main: darkTheme.color,
      },
      palette: {
        type: "dark",
        background: {
          default: darkTheme.background,
          paper: darkTheme.paperBg,
        },
        contrastText: darkTheme.color,
        primary: {
          main: darkTheme.color,
        },
        neutral: {
          main: darkTheme.color,
          secondary: darkTheme.gray,
        },
        text: {
          primary: darkTheme.color,
          secondary: darkTheme.gray,
        },
        graphStrokeColor: darkTheme.graphStrokeColor,
        highlight: darkTheme.textHighlightColor,
      },
      typography: {
        fontFamily: "Square"
      },
      props: {
        MuiSvgIcon: {
          htmlColor: darkTheme.color,
        },
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "@font-face": fonts,
            body: {
              background: darkTheme.background,
            },
          },
        },
        MuiDrawer: {
          paper: {
            background: darkTheme.drawerBg,
            backgroundColor: darkTheme.drawerBg,
            zIndex: 7,
          },
        },
        MuiPaper: {
          root: {
            background: darkTheme.drawerBg,
            // "&.ohm-card": {
            //   background: darkTheme.paperBgColor,
            // },
            "&.ohm-modal": {
              background: darkTheme.modalBg,
              backdropFilter: "none",
            },
            "&.ohm-menu": {
              backgroundColor: darkTheme.paperBgColor,
              backdropFilter: "blur(33px)",
            },
            "&.ohm-menuv2": {
              backgroundColor: "linear-gradient(180deg, #000809 0%, #031A14 100%);",
              backdropFilter: "blur(33px)",
              borderRadius: "8px",
              // padding:''
            },
            "&.ohm-popover": {
              background: darkTheme.paperBgColor,
              color: darkTheme.color,
              backdropFilter: "blur(15px)",
            },
            "&.ido-card": {
              background: darkTheme.paperBgColor,
            },
            "&.ido-card-infos": {
              background: darkTheme.paperBgColor,
            },
            "&.ido-card-desc": {
              background: darkTheme.paperBgColor,
            },
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: darkTheme.backdropBg,
          },
        },
        MuiLink: {
          root: {
            color: darkTheme.color,
            "&:hover": {
              color: darkTheme.textHighlightColor,
              textDecoration: "none",
              "&.active": {
                color: darkTheme.color,
              },
            },
            "&.active": {
              color: darkTheme.color,
              textDecoration: "underline",
            },
            "&.ido": {
              borderBottom: `1px solid ${darkTheme.idoBorder}`,
              borderTop: `1px solid ${darkTheme.idoBorder}`,
            },
          },
        },
        MuiTableCell: {
          root: {
            color: darkTheme.color,
          },
        },
        MuiInputBase: {
          root: {
            // color: darkTheme.gold,
          },
        },
        MuiOutlinedInput: {
          notchedOutline: {
            // borderColor: `${darkTheme.gold} !important`,
            "&:hover": {
              // borderColor: `${darkTheme.gold} !important`,
            },
          },
        },
        MuiTab: {
          textColorPrimary: {
            color: "#a3a3a3",
            "&$selected": {
              color: darkTheme.primaryButtonBG,
            },
          },
        },
        PrivateTabIndicator: {
          colorPrimary: {
            backgroundColor: darkTheme.primaryButtonBG,
          },
        },
        MuiToggleButton: {
          root: {
            backgroundColor: darkTheme.paperBg,
            "&:hover": {
              color: darkTheme.color,
              backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
            },
            selected: {
              backgroundColor: darkTheme.containedSecondaryButtonHoverBG,
            },
            "@media (hover:none)": {
              "&:hover": {
                color: darkTheme.color,
                backgroundColor: darkTheme.paperBg,
              },
              "&:focus": {
                color: darkTheme.color,
                backgroundColor: darkTheme.paperBg,
                borderColor: "transparent",
                outline: "#00000000",
              },
            },
          },
        },
        MuiButton: {
          containedPrimary: {
            color: "#000",
            backgroundColor: darkTheme.primaryButtonBG,
            "&:hover": {
              backgroundColor: darkTheme.primaryButtonHoverBG,
              color: darkTheme.primaryButtonHoverColor,
            },
            "&:active": {
              backgroundColor: darkTheme.primaryButtonHoverBG,
              color: darkTheme.primaryButtonHoverColor,
            },
            "@media (hover:none)": {
              color: darkTheme.primaryButtonColor,
              backgroundColor: darkTheme.gold,
              "&:hover": {
                backgroundColor: darkTheme.primaryButtonHoverBG,
              },
            },
          },
          containedSecondary: {
            backgroundColor: darkTheme.paperBg,
            color: darkTheme.color,
            "&:hover": {
              backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
            },
            "&:active": {
              backgroundColor: darkTheme.containedSecondaryButtonHoverBG,
            },
            "&:focus": {
              backgroundColor: darkTheme.paperBg,
            },
            "@media (hover:none)": {
              color: darkTheme.color,
              backgroundColor: darkTheme.paperBg,
              "&:hover": {
                backgroundColor: `${darkTheme.containedSecondaryButtonHoverBG} !important`,
              },
            },
          },
          outlinedPrimary: {
            color: darkTheme.primaryButtonBG,
            borderColor: darkTheme.primaryButtonBG,
            "&:hover": {
              color: darkTheme.outlinedPrimaryButtonHoverColor,
              backgroundColor: darkTheme.primaryButtonHoverBG,
              borderColor: darkTheme.outlinedSecondaryButtonHoverBG,
            },
            "@media (hover:none)": {
              color: darkTheme.outlinedPrimaryButtonHoverColor,
              // borderColor: darkTheme.primaryButtonBG,
              "&:hover": {
                color: darkTheme.outlinedPrimaryButtonHoverColor,
                backgroundColor: `${darkTheme.primaryButtonHoverBG} !important`,
                textDecoration: "none !important",
              },
            },
          },
          outlinedSecondary: {
            color: darkTheme.color,
            borderColor: darkTheme.color,
            "&:hover": {
              color: darkTheme.outlinedSecondaryButtonHoverColor,
              backgroundColor: darkTheme.outlinedSecondaryButtonHoverBG,
              borderColor: darkTheme.gold,
            },
          },
          textPrimary: {
            color: "#A3A3A3",
            "&:hover": {
              color: darkTheme.gold,
              backgroundColor: "#00000000",
            },
            "&:active": {
              color: darkTheme.gold,
              borderBottom: "#F8CC82",
            },
          },
          textSecondary: {
            color: darkTheme.color,
            "&:hover": {
              color: darkTheme.textHighlightColor,
            },
          },
        },

        MuiGrid: {
          root: {
            "&.ido-grid": {
              border: `1px solid ${darkTheme.idoGridBorder}`,
            },
            "&.ido-grid-btn": {
              padding: `0 !important`,
            },
          },
        },

        MuiTypography: {
          root: {
            "&.ido-typography": {
              // color: "red",
              textShadow: "#fff 0 0 10px",
            },
          }
        },
      },
    },
    commonSettings,
  ),
);
