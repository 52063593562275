import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import ierc20ABIJson from "../abi/IERC20.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";
import StakingHelperABIJson from "../abi/StakingHelper.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError, IBaseAddressAsyncThunk } from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";
import { t } from "@lingui/macro";
import { Web3Provider } from "@ethersproject/providers";

const ierc20ABI = ierc20ABIJson.abi;
const OlympusStakingABI = OlympusStakingABIJson.abi;
const StakingHelperABI = StakingHelperABIJson.abi;

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ token, provider, address, networkID }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    // const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // let approveTx;
    // approveTx = await ohmContract.approve(
    //   addresses[networkID].STAKING_HELPER_ADDRESS,
    //   ethers.utils.parseUnits("1000000000", "gwei").toString(),
    // );
    // console.log("approveTx", approveTx);

    const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
    // const signer = provider;
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    let approveTx;
    // let stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    // let unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);

    // return early if approval has already happened
    // if (alreadyApprovedToken(token, stakeAllowance, unstakeAllowance)) {
    //   dispatch(info("Approval completed."));
    //   return dispatch(
    //     fetchAccountSuccess({
    //       staking: {
    //         ohmStake: +stakeAllowance,
    //         ohmUnstake: +unstakeAllowance,
    //       },
    //     }),
    //   );
    // }
    try {
      if (token === "ohm") {
        // won't run if stakeAllowance > 0
        const estimateGas = await ohmContract.estimateGas.approve(
          addresses[networkID].STAKING_HELPER_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
        );

        approveTx = await ohmContract.approve(
          addresses[networkID].STAKING_HELPER_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (token === "sOhm") {
        const estimateGas = await sohmContract.estimateGas.approve(
          addresses[networkID].STAKING_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
        );
        approveTx = await sohmContract.approve(
          addresses[networkID].STAKING_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      }

      const text = "Approve " + (token === "ohm" ? "Staking" : "Unstaking");
      const pendingTxnType = token === "ohm" ? "approve_staking" : "approve_unstaking";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));

        await approveTx.wait();
      }
    } catch (e: unknown) {
      // dispatch(error((e as IJsonRPCError).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    // go get fresh allowances
    const stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    const unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);

    return dispatch(
      fetchAccountSuccess({
        staking: {
          ohmStake: +stakeAllowance,
          ohmUnstake: +unstakeAllowance,
        },
      }),
    );
  },
);

export const changeStake = createAsyncThunk(
  "stake/changeStake",
  async ({ action, value, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
    // const signer = provider;
    const staking = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingABI,
      signer,
    ) as OlympusStakingv2;
    const stakingHelper = new ethers.Contract(
      addresses[networkID].STAKING_HELPER_ADDRESS as string,
      StakingHelperABI,
      signer,
    ) as StakingHelper;

    let stakeTx;

    try {
      if (action === "stake") {
        const estimateGas = await stakingHelper.estimateGas.stake(ethers.utils.parseUnits(value, "gwei"));
        stakeTx = await stakingHelper.stake(ethers.utils.parseUnits(value, "gwei"), {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        });
      } else {
        const estimateGas = await staking.estimateGas.unstake(ethers.utils.parseUnits(value, "gwei"), true);

        stakeTx = await staking.unstake(ethers.utils.parseUnits(value, "gwei"), true, {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        });
      }
      const pendingTxnType = action === "stake" ? "staking" : "unstaking";
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(action), type: pendingTxnType }));
      await stakeTx.wait();
    } catch (e: unknown) {
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (stakeTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  },
);

export const cliamWarmupBalance = createAsyncThunk(
  "stake/cliamWarmupBalance",
  async ({ provider, address, networkID }: IBaseAddressAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
    // const signer = provider;
    const staking = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingABI,
      signer,
    ) as OlympusStakingv2;
    let claimTx;
    try {
      const estimateGas = await staking.estimateGas.claim(address);
      claimTx = await staking.claim(address, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const pendingTxnType = "claim";
      dispatch(fetchPendingTxns({ txnHash: claimTx.hash, text: "claim", type: pendingTxnType }));
      await claimTx.wait();
    } catch (e: unknown) {
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (claimTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(claimTx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  },
);
