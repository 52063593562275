import { useQuery } from "react-query";
import apollo from "src/lib/apolloClient";
import { treasuryDataQuery } from "../treasuryData";

export const useTreasuryMetrics = options => {
  return useQuery(
    "treasury_metrics",
    async () => {
      try {
        const response = await apollo(treasuryDataQuery);
        console.log("response useTreasuryMetrics", response);
        // Transform string values to floats
        return response.data.protocolMetrics.map(metric =>
          Object.entries(metric).reduce((obj, [key, value]) => ((obj[key] = parseFloat(value)), obj), {}),
        );
      } catch (error) {
        console.log("error", error);
      }
    },
    options,
  );
};


export const useTreasuryMetrics2 = options => {
    return useQuery(
        "treasury_metrics2",
        async () => {
            try {
                //   const response = await apollo(treasuryDataQuery);
                // console.log("response useTreasuryMetrics", response);
                // Transform string values to floats
                //   const result =  response.data.protocolMetrics.map(metric =>
                //     Object.entries(metric).reduce((obj, [key, value]) => ((obj[key] = parseFloat(value)), obj), {}),
                //     // Object.keys(metric).forEach(k=>metric[k]=parseFloat(metric[k]))
                //   );
                const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URL}/metricsv2`, {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                let data = await response.json();
                data = data.map(metric=>{
                    Object.keys(metric).forEach(k=>metric[k]=parseFloat(metric[k]))
                    return metric
                })
                console.log('[debug]metricsv2 result:',data);
                return data;
            } catch (error) {
                console.log("error", error);
            }
        },
        options,
    );
};
