import React from "react";
import { Box, Typography, Paper, Link } from "@mui/material";
import ParticipateBg from "../../assets/images/participateBg.webp";
import StakingData from "../../assets/images/en/EN1.png";
import StakingDataZH from "../../assets/images/zh/CN1.png";
import StakingDataVN from "../../assets/images/vn/VNM1.png";
import StakingDataKO from "../../assets/images/ko/KOR1.png";

import StakingAPY from "../../assets/images/en/EN2.png";
import StakingAPYZH from "../../assets/images/zh/CN2.webp";
import StakingAPYVN from "../../assets/images/vn/VNM2.png";
import StakingAPYKO from "../../assets/images/ko/KOR2.png";

import BondData from "../../assets/images/en/EN3.png";
import BondDataZH from "../../assets/images/zh/CN3.png";
import BondDataVN from "../../assets/images/vn/VNM3.png";
import BondDataKO from "../../assets/images/ko/KOR3.png";

import BondDataID from "../../assets/images/id/3.png";
import StakingDataID from "../../assets/images/id/1.png";
import StakingAPYID from "../../assets/images/id/2.png";

import { Trans } from "@lingui/macro";
import { useMobile } from "../../hooks";
import { i18n } from "@lingui/core";

const images = {
  en: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  zh: {
    stakingData: StakingDataZH,
    stakingAPY: StakingAPYZH,
    bondData: BondDataZH,
  },
  vi: {
    stakingData: StakingDataVN,
    stakingAPY: StakingAPYVN,
    bondData: BondDataVN,
  },
  ko: {
    stakingData: StakingDataKO,
    stakingAPY: StakingAPYKO,
    bondData: BondDataKO,
  },
  ja: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  es: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  id: {
    stakingData: StakingDataID,
    stakingAPY: StakingAPYID,
    bondData: BondDataID,
  },
  ar: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  de: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  fr: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  ru: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  it: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
  hi: {
    stakingData: StakingData,
    stakingAPY: StakingAPY,
    bondData: BondData,
  },
};

const HomeParticipate = () => {
  const { isSmallScreen } = useMobile();
  // console.log("i18n.locale", i18n.locale);
  return (
    <Box sx={{ height: "auto", mb: isSmallScreen ? 6.25 : 15 }}>
      <Typography
        sx={{
          color: "#C7C8CC",
          fontFamily: "Neue Haas Grotesk Text Pro",
          fontSize: isSmallScreen ? "24px" : "54px",
          fontStyle: "normal",
          fontWeight: "700",
          mb: isSmallScreen ? 2.5 : 7.5,
        }}
        align="center"
      >
        <Trans>How to Participate</Trans>
      </Typography>
      <Paper
        className="linear-card"
        sx={
          {
            // borderRadius: isSmallScreen ? "12px" : "24px",
            // background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // border: isSmallScreen ? "1px solid #434952" : "2px solid #434952",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            // overflow: "hidden",
          }
        }
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: isSmallScreen ? "12px" : "24px",
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            position: "relative",
            px: isSmallScreen ? 2.5 : 11.25,
            py: isSmallScreen ? 4.5 : 7.5,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "-100px",
              left: "-130px",
              width: "990px",
              height: "758px",
              zIndex: 0,
              background: `url(${ParticipateBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Box sx={{ position: "relative", zIndex: 1 }}>
            <Box
              display="flex"
              flexDirection={isSmallScreen ? "column" : "row"}
              justifyContent={"space-between"}
              sx={{ pb: 6.25 }}
            >
              <Box sx={{ width: isSmallScreen ? "100%" : "430px" }}>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: isSmallScreen ? "24px" : "40px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    mb: isSmallScreen ? 3.5 : 3.75,
                  }}
                >
                  <Trans>Staking</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    mb: isSmallScreen ? 3.75 : 11,
                  }}
                >
                  <Trans>
                    The APY mechanism will increase the balance exponentially! Assuming that the daily compound interest
                    is 2%, if you start with a balance of 1 LGNS on the first day, after one year, your balance will
                    grow to about 1,377 LGNS.
                  </Trans>
                </Typography>
                <Box
                  href="/#/stake"
                  component={Link}
                  sx={{
                    width: "120px",
                    height: "42px",
                    borderRadius: "30px",
                    border: "1.3px solid #B50C05",
                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                    cursor: "pointer",
                    mb: isSmallScreen ? 5 : 0,
                    "&:hover": {
                      background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                    },
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography
                    sx={{
                      color: "#ebebeb",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>Stake</Trans>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: isSmallScreen ? "100%" : "auto",
                  "&>img": {
                    width: "100%",
                  },
                }}
              >
                <img src={((images as any)[i18n.locale] as any)["stakingData"]} alt="staking data" />
                {/* <img src={i18n.locale === "en" ? StakingData : StakingDataZH} alt="staking data" /> */}
              </Box>
            </Box>
            {/* line */}
            <Box
              sx={{
                width: "100%",
                height: "1px",
                background: "#1A1C1F",
                boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
              }}
            />
            {/* earning 2% */}
            <Box sx={{ pt: isSmallScreen ? 3.75 : 6.25, mb: isSmallScreen ? 3.75 : 11 }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: isSmallScreen ? "15px" : "30px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  mb: 2.75,
                }}
                align="center"
              >
                <Trans>$9</Trans>
              </Typography>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent={"center"}
                sx={{
                  width: isSmallScreen ? "100%" : "auto",
                  // borderRadius: "6.655px",
                  // border: "0.555px solid #434952",
                  overflow: "hidden",
                  // boxShadow: "3.88182px 3.88182px 27.72727px 0px rgba(0, 0, 0, 0.40)",
                  // background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                  "&>img": {
                    width: "100%",
                  },
                }}
              >
                <img src={((images as any)[i18n.locale] as any)["stakingAPY"]} alt="apy" />
              </Box>
            </Box>
            {/* line */}
            <Box
              sx={{
                width: "100%",
                height: "1px",
                background: "#1A1C1F",
                boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
              }}
            />
            <Box
              display="flex"
              flexDirection={isSmallScreen ? "column-reverse" : "row"}
              justifyContent={"space-between"}
              sx={{ pt: isSmallScreen ? 3.5 : 6.25 }}
            >
              <Box
                sx={{
                  width: isSmallScreen ? "100%" : "auto",
                  "&>img": {
                    width: "100%",
                  },
                }}
              >
                <img src={((images as any)[i18n.locale] as any)["bondData"]} alt="bond data" />
              </Box>
              <Box sx={{ width: isSmallScreen ? "100%" : "500px", pt: 2, mb: isSmallScreen ? 1.25 : 0 }}>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: isSmallScreen ? "24px" : "40px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    mb: isSmallScreen ? 3.5 : 3.75,
                  }}
                >
                  <Trans>Bond</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    mb: isSmallScreen ? 3.75 : 4.125,
                  }}
                >
                  <Trans>
                    The value of the bond is reflected in the market share you get at a certain LGNS discount.
                    Regardless of how the market price fluctuates, you will have a certain range of profits, and the
                    higher the price, the more value you will receive. Risk Free Value Added (RFV) is simply a process
                    that facilitates the relatively safe and continuous accumulation of treasury assets, culminating in
                    a medium of exchange agreement that favors reserves.
                  </Trans>
                </Typography>
                <Box
                  href="/#/bonds"
                  component={Link}
                  sx={{
                    width: "120px",
                    height: "42px",
                    borderRadius: "30px",
                    border: "1.3px solid #B50C05",
                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                    cursor: "pointer",
                    "&:hover": {
                      background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                    },
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography
                    sx={{
                      color: "#ebebeb",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>Bond</Trans>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default HomeParticipate;
