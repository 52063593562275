import React, { useEffect, useState } from "react";
import { Box, Typography, Zoom, Paper, Button, Grid, CircularProgress, Link, SvgIcon } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import Reward1 from "src/assets/images/reward1.png";
import Reward2 from "src/assets/images/reward2.png";
import Reward3 from "src/assets/images/reward3.png";
import NoData from "src/assets/images/noData.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { success, error } from "src/slices/MessagesSlice";

import TimeRewardMobile from "src/assets/images/timeReward.png";
import TimeReward from "src/assets/images/timeReward.png";

import MonthReward from "src/assets/images/monthReward.png";
import MonthRewardMobile from "src/assets/images/monthReward.png";
import WeekReward from "src/assets/images/weekReward.png";
import WeekRewardMobile from "src/assets/images/weekReward.png";
import RewardImg from "src/assets/icons/reward.svg";
import { useAppSelector } from "src/hooks";
import { useDispatch } from "react-redux";
import { ReactComponent as ArrowDownIcon } from "src/assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "src/assets/icons/arrow-up.svg";
import {
  getLotteryHourData,
  getAccountLotteryData,
  lotteryClaimReward,
  getLotteryWeekData,
  getLotteryMonthData,
} from "src/slices/CommunitySlice";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { useMobile } from "src/hooks";
import { fetchLotteryDataFromURL, shorten, trim } from "src/helpers";
import { LoadingButton, Skeleton } from "@mui/lab";
import { txnButtonText, isPendingTxn } from "src/slices/PendingTxnsSlice";
import { addresses } from "src/constants";
import { i18n } from "@lingui/core";
import SelectTime from "./SelectTime";
import { ethers } from "ethers";
import { useHistory } from "react-router-dom";
import { PullToRefresh } from "src/components/PullToRefresh";

const Reward = () => {
  const tabTitles = [t`Hour Reward`, t`Week Reward`, t`Month Reward`];
  const { isSmallScreen } = useMobile();
  const [activeReward, setActiveReward] = useState(0);
  const [currentTitle, setCurrentTitle] = useState(t`$7`);
  const [showMoreArray, setShowMoreArray] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { provider, chainID, connected } = useWeb3Context();
  const address = useAddress();
  const [winnerList, setWinnerList] = useState<any[] | null>(null);
  const [isShowThree, setIsShowThree] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const turboCurrentReward = useAppSelector(state => state.app.turboCurrentReward);
  const fomoCurrentReward = useAppSelector(state => state.app.fomoCurrentReward);
  const hourRewards = useAppSelector(state => state.community.hourRewards);
  const weekRewards = useAppSelector(state => state.community.weekRewards);
  const monthRewards = useAppSelector(state => state.community.monthRewards);
  const turboReward = useAppSelector(state => state.community.turboReward);
  const fomoReward = useAppSelector(state => state.community.fomoReward);
  const accountHourReward = useAppSelector(state => state.community.accountHourReward);
  const accountWeekReward = useAppSelector(state => state.community.accountWeekReward);
  const accountMonthReward = useAppSelector(state => state.community.accountMonthReward);
  // const hourThresholdSnapshot = useAppSelector(state => state.community.hourThresholdSnapshot);
  // const weekThresholdSnapshot = useAppSelector(state => state.community.weekThresholdSnapshot);
  // const monthThresholdSnapshot = useAppSelector(state => state.community.monthThresholdSnapshot);
  const hourWinnerList = useAppSelector(state => state.community.winnerList?.Hour);
  const weekWinnerList = useAppSelector(state => state.community.winnerList?.Week);
  const monthWinnerList = useAppSelector(state => state.community.winnerList?.Month);
  const isAppLoading = useAppSelector(state => state.app.loading);
  const [selectSequence, setSelectSequence] = useState<any>();
  // const winnerList = useAppSelector(state => state.community.winnerList);
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  let history = useHistory();

  // console.log("hourThresholdSnapshot", hourThresholdSnapshot, weekThresholdSnapshot, monthThresholdSnapshot);
  useEffect(() => {
    console.log("getLotteryHourData");
    dispatch(
      getLotteryHourData({
        provider,
        networkID: chainID,
      }),
    );
    if (address) {
      dispatch(
        getAccountLotteryData({
          provider,
          networkID: chainID,
          address,
        }),
      );
    }
  }, [address]);
  // useEffect(() => {

  //   dispatch(getLotteryHourData());
  // }, [address]);

  useEffect(() => {
    // setWinnerList(hourWinnerList as any[]);

    setWinnerList(
      activeReward === 0
        ? hourWinnerList ?? null
        : activeReward === 1
          ? weekWinnerList ?? null
          : monthWinnerList ?? null,
    );
    console.log(
      "winnerList",
      activeReward === 0
        ? hourWinnerList ?? null
        : activeReward === 1
          ? weekWinnerList ?? null
          : monthWinnerList ?? null,
    );
  }, [activeReward, hourWinnerList, weekWinnerList, monthWinnerList, selectSequence]);

  const handleShowMore = (number: number) => {
    if (showMoreArray.includes(number)) {
      setShowMoreArray(showMoreArray.filter(item => item !== number));
    } else {
      setShowMoreArray([...showMoreArray, number]);
    }
  };

  // type:  turbo  fomo
  const handleClaimReward = async (type: string, amount: string) => {
    // e.stopPropagation();
    // console.log("type", type);
    await dispatch(
      lotteryClaimReward({
        provider,
        networkID: chainID,
        address,
        type,
        amount,
      }),
    );
    dispatch(
      getAccountLotteryData({
        provider,
        networkID: chainID,
        address,
      }),
    );
  };
  // chain=bscTestnet&inputCurrency=0x4154a3560c8F4496B3dE5151759ede7861E321eE&outputCurrency=0xC650Ed3905586ec3B4765f7b1d0C05fC1906e3Aa
  const goPancakeSwap = () => {
    // window.open(
    //   `https://pancakeswap.finance/swap?chain=${chainID === 56 ? "bsc" : "bscTestnet"}&inputCurrency=${
    //     addresses[chainID].USDT_ADDRESS
    //   }&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
    // window.open(
    //   `https://quickswap.exchange/#/swap?inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
    history.push("/swap");
  };

  const handleSelectSequence = async (sequence: number) => {
    console.log("handleSelectSequence", sequence, activeReward);
    // dispatch(getLotteryHourData({}));
    setIsLoading(true);
    setIsShowThree(false);
    setWinnerList(null);
    setSelectSequence(sequence);
    if (activeReward === 0) {
      await dispatch(
        getLotteryHourData({
          roundSequence: sequence,
          provider,
          networkID: chainID,
        }),
      );
    } else if (activeReward === 1) {
      await dispatch(
        getLotteryWeekData({
          roundSequence: sequence,
          provider,
          networkID: chainID,
        }),
      );
    } else if (activeReward === 2) {
      await dispatch(
        getLotteryMonthData({
          roundSequence: sequence,
          provider,
          networkID: chainID,
        }),
      );
    }
    setIsLoading(false);
  };

  const handleChangeTab = async (tab: number) => {
    // setWinnerList(null);
    setSelectSequence(null);
    setActiveReward(tab);
    setIsShowThree(true);
    setIsLoading(true);
    if (tab === 0) {
      await dispatch(getLotteryHourData({ provider, networkID: chainID }));
      setIsLoading(false);
    } else if (tab === 1) {
      await dispatch(
        getLotteryWeekData({
          provider,
          networkID: chainID,
        }),
      );
      setIsLoading(false);
    } else {
      await dispatch(getLotteryMonthData({ provider, networkID: chainID }));
      setIsLoading(false);
    }
    // setIsLoading(false);
  };

  const shareReward = () => {
    dispatch(success(t`Copy successful!`));
  };

  return (
    <PullToRefresh onRefresh={async ()=>{
      return handleChangeTab(0);
    }}>


      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        sx={{
          pt: isSmallScreen ? 3.75 : 0,
          px: isSmallScreen ? 1.875 : 0,
        }}
      >
        <Zoom in={true} timeout={1000}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%", maxWidth: "834x", mb: isSmallScreen ? 1.875 : 2.5 }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "834px",
                borderRadius: "44px",
                border: "1px solid #3E434E",
                background: "#2E3138",
                boxShadow: "2px 4px 8px 0px #1A1C1F inset, -1px -1px 4px 0px #3E434E inset",
                p: 0.675,
              }}
              display={"flex"}
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  width: "100%",
                  height: "28px",
                  borderRadius: "15px",
                  border: activeReward == 0 ? "1px solid #5F6674" : "none",
                  background: activeReward == 0 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                  boxShadow: activeReward == 0 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                  cursor: "pointer",
                }}
                onClick={() => handleChangeTab(0)}
                display={"flex"}
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: isSmallScreen ? "12px" : "13px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  <Trans>Hour Ranking</Trans>

                  {/* 时时奖 */}
                  {/* <Trans>Turbine Prize Pool</Trans> */}
                </Typography>
              </Box>
              {/* <Box
              sx={{
                width: "100%",
                height: "28px",
                borderRadius: "15px",
                border: activeReward == 1 ? "1px solid #5F6674" : "none",
                background: activeReward == 1 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                boxShadow: activeReward == 1 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                cursor: "pointer",
              }}
              onClick={() => handleChangeTab(1)}
              display={"flex"}
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "AGaramond LT",
                  fontSize: isSmallScreen ? "12px" : "13px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
                日榜
              </Typography>
            </Box> */}
              <Box
                sx={{
                  width: "100%",
                  height: "28px",
                  borderRadius: "15px",
                  border: activeReward == 1 ? "1px solid #5F6674" : "none",
                  background: activeReward == 1 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                  boxShadow: activeReward == 1 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                  cursor: "pointer",
                }}
                onClick={() => handleChangeTab(1)}
                display={"flex"}
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: isSmallScreen ? "12px" : "13px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  <Trans>Week Ranking</Trans>

                  {/* 周榜 */}
                  {/* <Trans>FOMO Prize Pool</Trans> */}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "28px",
                  borderRadius: "15px",
                  border: activeReward == 2 ? "1px solid #5F6674" : "none",
                  background: activeReward == 2 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                  boxShadow: activeReward == 2 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                  cursor: "pointer",
                }}
                onClick={() => handleChangeTab(2)}
                display={"flex"}
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: isSmallScreen ? "12px" : "13px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  {/*  */}
                  {/* 月榜 */}
                  <Trans>Month Ranking</Trans>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Zoom>
        <Zoom in={true} timeout={1000}>
          <Paper
            className="ohm-card linear-card"
            sx={{
              // px: 3.75,
              // py: 2.5,
              mb: 3.125,
            }}
          >
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: isSmallScreen ? "12px" : "24px",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
              }}
            // sx={{ pt: 5.75, pb: 3.75 }}
            >
              <Box
                display="flex"
                alignItems="cneter"
                justifyContent="space-between"
                sx={{ px: isSmallScreen ? 2.5 : 3.75, py: isSmallScreen ? 1.875 : 2.625, width: "100%" }}
              >
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "21px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  {tabTitles[activeReward]}
                  {/* {activeReward === 0 ? <Trans>Turbine Prize Pool</Trans> : <Trans>FOMO Prize Pool</Trans>} */}
                </Typography>
                <Box display="flex" alignItems="center">
                  {(activeReward == 0 || activeReward == 1) && (
                    <Typography
                      sx={{
                        color: "#F5D55C",
                        fontSize: "16px",
                        fontWeight: "400",
                        mr: 1,
                      }}
                    >
                      <Trans>$10</Trans>:
                    </Typography>
                  )}
                  {activeReward == 0 &&
                    (hourRewards ? (
                      <Typography
                        align="right"
                        sx={{
                          color: "#F5D55C",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >{`$${Number(hourRewards) >= 20000 ? "0" : trim(20000 - Number(trim(Number(hourRewards), 2)), 2)
                        }`}</Typography>
                    ) : (
                      <Skeleton width="50px" />
                    ))}
                  {activeReward == 1 &&
                    (weekRewards ? (
                      <Typography
                        align="right"
                        sx={{
                          color: "#F5D55C",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >{`$${Number(weekRewards) >= 50000 ? "0" : trim(50000 - Number(trim(Number(weekRewards), 2)), 2)
                        }`}</Typography>
                    ) : (
                      <Skeleton width="50px" />
                    ))}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  background: "#434952",
                  boxShadow: "0px 2px 8px 0px rgba(26, 28, 31, 0.80)",
                  mb: 4,
                }}
              />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "AGaramond LT",
                    fontSize: isSmallScreen ? "40px" : "60px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    mb: 4,
                  }}
                >
                  {isAppLoading ||
                    (activeReward === 0 && !hourRewards) ||
                    (activeReward === 1 && !weekRewards) ||
                    (activeReward === 2 && !monthRewards) ? (
                    <Skeleton width="200px" />
                  ) : (
                    (activeReward === 0 && `$${trim(Number(hourRewards), 2)}`) ||
                    (activeReward === 1 &&
                      weekRewards &&
                      `$${Number(weekRewards) >= 0.01 ? trim(Number(weekRewards), 2) : "0.00"}`) ||
                    (activeReward === 2 && monthRewards && `$${trim(Number(monthRewards), 2)}`)
                  )}
                </Typography>
                {/* note */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ px: isSmallScreen ? 2.5 : 8.375, mb: 5, width: "100%" }}
                >
                  <Box
                    display="flex"
                    sx={{
                      width: "100%",
                    }}
                  >
                    {!isSmallScreen && (
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>Note:</Trans>
                      </Typography>
                    )}
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          mb: 0.875,
                        }}
                      >
                        {isSmallScreen && <Trans>Note:</Trans>}
                        {activeReward === 0 && <Trans>$1</Trans>}
                        {activeReward === 1 && <Trans>$2</Trans>}
                        {activeReward === 2 && <Trans>$3</Trans>}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#F5D55C",
                          fontFamily: "AGaramond LT",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        {activeReward === 0 && <Trans>$4</Trans>}
                        {activeReward === 1 && <Trans>$5</Trans>}
                        {activeReward === 2 && <Trans>$6</Trans>}
                        {/* {activeReward === 0 ? (
                        <Trans>2.1st Place Score 50% 2nd-4th Average 30% 5th-10th Average 20%</Trans>
                      ) : (
                        <Trans>2.Allocate rewards based on the weight of purchased tokens.</Trans>
                      )} */}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* reward */}

                <Box
                  sx={{
                    mb: 5,
                    width: "100%",
                    px: isSmallScreen ? 2.5 : 8.375,
                    "& > img": {
                      width: isSmallScreen ? "100%" : "496px",
                    },
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isSmallScreen ? (
                    <>
                      {activeReward === 0 && (
                        <img src={i18n.locale === "en" ? TimeReward : TimeReward} alt="time reward" />
                      )}

                      {activeReward === 1 && (
                        <img src={i18n.locale === "en" ? WeekReward : WeekReward} alt="week reward" />
                      )}
                      {activeReward === 2 && (
                        <img src={i18n.locale === "en" ? MonthReward : MonthReward} alt="daily reward" />
                      )}
                    </>
                  ) : (
                    <>
                      {activeReward === 0 && (
                        <img src={i18n.locale === "en" ? TimeRewardMobile : TimeRewardMobile} alt="time reward" />
                      )}

                      {activeReward === 1 && (
                        <img src={i18n.locale === "en" ? WeekRewardMobile : WeekRewardMobile} alt="week reward" />
                      )}
                      {activeReward === 2 && (
                        <img src={i18n.locale === "en" ? MonthRewardMobile : MonthReward} alt="daily reward" />
                      )}
                    </>
                  )}
                </Box>
                {/* )} */}
                {/* reward action */}

                <Box sx={{ width: "100%", px: isSmallScreen ? 2.5 : 20.25, mb: "30px" }} display="flex">
                  {connected &&
                    ((activeReward === 0 && typeof accountHourReward !== "string") ||
                      (activeReward === 1 && typeof accountWeekReward !== "string") ||
                      (activeReward === 2 && typeof accountMonthReward !== "string")) ? (
                    <Skeleton variant="rectangular" width="100%" height="40px" />
                  ) : (
                    <Box sx={{ width: "100%" }} display="flex">
                      <Box
                        sx={{ width: "100%", mr: isSmallScreen ? 1.25 : 3.75 }}
                        display="flex"
                        flexDirection="column"
                        alignItems="cneter"
                      >
                        {activeReward === 0 ? (
                          <LoadingButton
                            variant="contained"
                            onClick={() => handleClaimReward("0", accountHourReward as string)}
                            loading={isPendingTxn(pendingTransactions, "reward_pool_claim")}
                            disabled={
                              isPendingTxn(pendingTransactions, "reward_pool_claim") ||
                              Number(accountHourReward) <= 0 ||
                              !connected
                            }
                            loadingPosition="end"
                            sx={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "30px",
                              border: "1.3px solid #B50C05",
                              background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                              p: 0,
                              mb: isSmallScreen ? 0 : 1,
                              "&.Mui-disabled": {
                                opacity: 0.5,
                                cursor: "not-allowed",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                "&> img": {
                                  width: "30px",
                                  height: "31px",
                                  mr: 1.125,
                                },
                              }}
                              // onClick={() => handleClaimReward("fomo")}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {Number(accountHourReward) > 0 &&
                                !isPendingTxn(pendingTransactions, "reward_pool_claim") && <img src={RewardImg} />}
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "16px" : "18px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  textTransform: "none",
                                }}
                              >
                                {Number(accountHourReward) > 0 ? (
                                  isPendingTxn(pendingTransactions, "reward_pool_claim") ? (
                                    t`Pending...`
                                  ) : (
                                    <>{`$${trim(Number(accountHourReward), 2)}`}</>
                                  )
                                ) : (
                                  <Trans>No rewards yet</Trans>
                                )}
                              </Typography>
                            </Box>
                          </LoadingButton>
                        ) : activeReward === 1 ? (
                          <LoadingButton
                            variant="contained"
                            onClick={() => handleClaimReward("1", accountWeekReward as string)}
                            loading={isPendingTxn(pendingTransactions, "reward_pool_claim")}
                            disabled={
                              isPendingTxn(pendingTransactions, "reward_pool_claim") ||
                              Number(accountWeekReward) <= 0 ||
                              !connected
                            }
                            className="second-btn"
                            loadingPosition="end"
                            sx={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "30px",
                              border: "1.3px solid #B50C05",
                              background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                              p: 0,
                              mb: isSmallScreen ? 0 : 1,
                              "&.Mui-disabled": {
                                opacity: 0.5,
                                cursor: "not-allowed",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                "&> img": {
                                  width: "30px",
                                  height: "31px",
                                  mr: 1.125,
                                },
                              }}
                              // onClick={() => handleClaimReward("fomo")}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {Number(accountWeekReward) > 0 &&
                                !isPendingTxn(pendingTransactions, "reward_pool_claim") && <img src={RewardImg} />}
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "16px" : "18px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  textTransform: "none",
                                }}
                              >
                                {Number(accountWeekReward) > 0 ? (
                                  isPendingTxn(pendingTransactions, "reward_pool_claim") ? (
                                    t`Pending...`
                                  ) : (
                                    <>{`$${trim(Number(accountWeekReward), 2)}`}</>
                                  )
                                ) : (
                                  <Trans>No rewards yet</Trans>
                                )}
                              </Typography>
                            </Box>
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            variant="contained"
                            onClick={() => handleClaimReward("2", accountMonthReward as string)}
                            loading={isPendingTxn(pendingTransactions, "reward_pool_claim")}
                            disabled={
                              isPendingTxn(pendingTransactions, "reward_pool_claim") ||
                              Number(accountMonthReward) <= 0 ||
                              !connected
                            }
                            className="second-btn"
                            loadingPosition="end"
                            sx={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "30px",
                              border: "1.3px solid #B50C05",
                              background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                              p: 0,
                              mb: isSmallScreen ? 0 : 1,
                              "&.Mui-disabled": {
                                opacity: 0.5,
                                cursor: "not-allowed",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                "&> img": {
                                  width: "30px",
                                  height: "31px",
                                  mr: 1.125,
                                },
                              }}
                              // onClick={() => handleClaimReward("fomo")}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {Number(accountMonthReward) > 0 &&
                                !isPendingTxn(pendingTransactions, "reward_pool_claim") && <img src={RewardImg} />}
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "16px" : "18px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  textTransform: "none",
                                }}
                              >
                                {Number(accountMonthReward) > 0 ? (
                                  isPendingTxn(pendingTransactions, "reward_pool_claim") ? (
                                    t`Pending...`
                                  ) : (
                                    <>{`$${trim(Number(accountMonthReward), 2)}`}</>
                                  )
                                ) : (
                                  <Trans>No rewards yet</Trans>
                                )}
                              </Typography>
                            </Box>
                          </LoadingButton>
                        )}
                        {!isSmallScreen && (activeReward === 0 ? Number(turboReward) > 0 : Number(fomoReward) > 0) && (
                          <Typography
                            sx={{
                              color: "rgba(255, 255, 255, 0.50)",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "510",
                              lineHeight: "16px" /* 133.333% */,
                            }}
                            align="center"
                          >
                            <Trans>Click to claim to wallet</Trans>
                          </Typography>
                        )}
                      </Box>
                      <Button
                        variant="contained"
                        className="main-btn"
                        onClick={goPancakeSwap}
                        sx={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "30px",
                          border: "1.3px solid #B50C05",
                          background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                          boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                          p: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "AGaramond LT",
                            fontSize: isSmallScreen ? "16px" : "18px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            textTransform: "none",
                          }}
                        >
                          <Trans>Buy LGNS</Trans>
                        </Typography>
                      </Button>
                    </Box>
                  )}
                </Box>
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  sx={{
                    mb: 7,
                  }}
                >
                  <CopyToClipboard text={`${window.location.href}`}>
                    <Button
                      variant="contained"
                      // className="main-btn"
                      onClick={shareReward}
                      sx={{
                        width: "240px",
                        height: "40px",
                        borderRadius: "24px",
                        border: "2px solid #D17D1C",
                        background: "linear-gradient(180deg, #F2D96F 0%, #E99228 100%)",
                        boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                        p: 0,
                        "&:hover": {
                          background: "linear-gradient(180deg, #E99228 0%, #F2D96F 100%) !important",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#121212",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        <Trans>$11</Trans>
                      </Typography>
                    </Button>
                  </CopyToClipboard>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Zoom>
        <Zoom in={true} timeout={1000}>
          <Paper
            className="ohm-card linear-card"
            sx={{
              mb: 3.125,
            }}
          >
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: isSmallScreen ? "12px" : "24px",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
              }}
            // sx={{ pt: 5.75, pb: 3.75 }}
            >
              <Box
                sx={{ px: isSmallScreen ? 2.5 : 3.75, py: 2.625, width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: isSmallScreen ? "18px" : "21px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  <Trans>Three recent winners</Trans>
                  {/* <Trans>Winning Address for this Issue</Trans> */}
                </Typography>
                <SelectTime
                  rewardType={tabTitles[activeReward]}
                  currentTitle={currentTitle}
                  activeReward={activeReward}
                  handleSelectSequence={handleSelectSequence}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  background: "#434952",
                  boxShadow: "0px 2px 8px 0px rgba(26, 28, 31, 0.80)",
                  mb: 3,
                }}
              />
              <Box
                sx={{ px: isSmallScreen ? 2.5 : 3.75, width: "100%", minHeight: "189px" }}
                display={"flex"}
                flexDirection={"column"}
                alignItems="center"
                justifyContent={
                  !winnerList || winnerList.length === 0 || winnerList[0].length === 0 ? "center" : "flex-start"
                }
              >
                {!winnerList || isLoading ? (
                  <CircularProgress sx={{ color: "#868B8F" }} />
                ) : (
                  <>
                    {/* <Grid container sx={{ width: "100%" }} rowSpacing={3} columnSpacing={isSmallScreen ? 1 : 0}> */}
                    {winnerList &&
                      winnerList.length > 0 &&
                      // winnerList[0].length > 0 &&
                      (isShowThree && winnerList.length >= 1 ? (
                        winnerList.map((winners: any, index: number) => {
                          console.log("winnerList winner", index, winners);
                          return (
                            <Box
                              key={index}
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Box sx={{ width: "100%", mb: "15px" }} display="flex" alignItems={"center"}>
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    mr: 1,
                                  }}
                                >
                                  {`${tabTitles[activeReward]} ${winners[0] ? winners[0].roundSequence + 1 : winners.roundSequence + 1
                                    }`}
                                  <Trans>sequence</Trans>
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >{`${winners[0] ? winners[0].clearTime : winners.clearTime}`}</Typography>
                              </Box>
                              <Grid container sx={{ width: "100%" }} rowSpacing={3} columnSpacing={isSmallScreen ? 1 : 0}>
                                {winners.map((winner: any, i: number) => {
                                  if (i > 3 && isSmallScreen && !showMoreArray.includes(index)) return;
                                  else
                                    return (
                                      <Grid item xs={12} md={3} key={i}>
                                        <Link
                                          href={`${process.env.REACT_APP_PUBLIC_SCAN}/${winner.proofTxHash}`}
                                          target="_blank"
                                        >
                                          <Box
                                            sx={{ width: "100%" }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent={
                                              isSmallScreen
                                                ? "flex-start"
                                                : i % 4 === 3
                                                  ? "flex-end"
                                                  : i % 4 === 0
                                                    ? "flex-start"
                                                    : "center"
                                            }
                                          >
                                            <Typography
                                              sx={{
                                                color: "rgba(0, 112, 215, 1)",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {shorten(winner.proofTxHash)}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                color: "#fff",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                ml: 1,
                                              }}
                                            >
                                              ({`$${trim(winner.rewards, 2)}`})
                                            </Typography>
                                          </Box>
                                        </Link>
                                      </Grid>
                                    );
                                })}
                              </Grid>
                              {winners.length > 4 && isSmallScreen && (
                                <Box
                                  display={"flex"}
                                  alignItems="center"
                                  justifyContent={"center"}
                                  sx={{
                                    pt: "10px",
                                  }}
                                >
                                  {
                                    showMoreArray?.includes(index) ?
                                      <Box display="flex" alignItems={"center"} onClick={() => handleShowMore(index)}>

                                        <Typography
                                          sx={{
                                            color: "#7e58fa",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            mr: 1,
                                          }}
                                        >
                                          <Trans>Collapse</Trans>
                                        </Typography>
                                        <SvgIcon
                                          viewBox="0 0 15 8"
                                          sx={{
                                            width: "15px",
                                            transform: 'rotate(180deg)'
                                          }}
                                          component={ArrowDownIcon}
                                          htmlColor="#B50C05"
                                        />
                                      </Box> :
                                      <Box display="flex" alignItems={"center"} onClick={() => handleShowMore(index)}>

                                        <Typography
                                          sx={{
                                            color: "#7e58fa",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            mr: 1,
                                          }}
                                        >
                                          <Trans>Open</Trans>
                                        </Typography>
                                        <SvgIcon
                                          viewBox="0 0 15 8"
                                          sx={{
                                            width: "15px",
                                          }}
                                          component={ArrowDownIcon}
                                          htmlColor="#B50C05"
                                        />
                                      </Box>
                                  }

                                </Box>
                              )}
                              <Box
                                sx={{ width: "100%", height: "1px", background: "rgba(134, 139, 143, 0.30)", my: "25px" }}
                              />
                            </Box>
                          );
                        })
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Box sx={{ width: "100%", mb: "15px" }} display="flex" alignItems="center">
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: "400",
                                mr: 1,
                              }}
                            >
                              {selectSequence && selectSequence >= 0
                                ? selectSequence + 1
                                : winnerList[0]
                                  ? winnerList[0].roundSequence + 1
                                  : winnerList[0][0].roundSequence + 1}
                              <Trans>sequence</Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >{`${selectSequence && selectSequence >= 0
                                ? winnerList[0].clearTime
                                  ? winnerList[0].clearTime
                                  : winnerList[0][0].clearTime
                                : winnerList[0].clearTime
                              }`}</Typography>
                          </Box>
                          <Grid container sx={{ width: "100%" }} rowSpacing={3} columnSpacing={isSmallScreen ? 1 : 0}>
                            {winnerList.map((winner: any, index: number) => {
                              console.log(
                                "winner",
                                winner,
                                winner.length,
                                `${process.env.REACT_APP_PUBLIC_SCAN}/${winner.proofTxHash}`,
                              );
                              if (winner.length > 0) {
                                return winner.map((_winner: any, i: number) => (
                                  <Grid item xs={12} md={3} key={i}>
                                    <Link
                                      href={`${process.env.REACT_APP_PUBLIC_SCAN}/${_winner.proofTxHash}`}
                                      target="_blank"
                                    >
                                      <Box
                                        sx={{ width: "100%" }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={
                                          isSmallScreen
                                            ? "flex-start"
                                            : i % 4 === 3
                                              ? "flex-end"
                                              : i % 4 === 0
                                                ? "flex-start"
                                                : "center"
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            color: "rgba(0, 112, 215, 1)",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {shorten(_winner.proofTxHash)}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: "#b50c05",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            ml: 1,
                                          }}
                                        >
                                          ({`$${trim(_winner.rewards, 2)}`})
                                        </Typography>
                                      </Box>
                                    </Link>
                                  </Grid>
                                ));
                              } else {
                                return (
                                  <Grid item xs={6} md={3} key={index}>
                                    <Link
                                      href={`${process.env.REACT_APP_PUBLIC_SCAN}/${winner.proofTxHash}`}
                                      target="_blank"
                                    >
                                      <Box
                                        sx={{ width: "100%" }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={
                                          isSmallScreen
                                            ? "flex-start"
                                            : index % 4 === 3
                                              ? "flex-end"
                                              : index % 4 === 0
                                                ? "flex-start"
                                                : "center"
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            color: "rgba(0, 112, 215, 1)",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {shorten(winner.proofTxHash)}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: "#b50c05",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            ml: 1,
                                          }}
                                        >
                                          ({`$${trim(winner.rewards, 2)}`})
                                        </Typography>
                                      </Box>
                                    </Link>
                                  </Grid>
                                );
                              }
                            })}
                          </Grid>
                          <Box
                            sx={{ width: "100%", height: "1px", background: "rgba(134, 139, 143, 0.30)", my: "25px" }}
                          />
                        </Box>
                      ))}
                    {/* </Grid> */}
                    {winnerList && (winnerList.length === 0 || winnerList[0].length === 0) && (
                      <Box
                        sx={{ width: "100%", height: "100%" }}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <img src={NoData} alt="No Data" />
                        <Typography
                          sx={{
                            pt: 2,
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>No data</Trans>
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Paper>
        </Zoom>
      </Box>
    </PullToRefresh>
  );
};

export default Reward;
