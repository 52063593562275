import { t, Trans } from "@lingui/macro";
import { Box, Button, Paper, Table, TableCell, TableHead, Typography, TableRow, TableBody,CircularProgress } from "@mui/material"
import axios from "axios";
import dayjs from "dayjs";

import { useEffect, useState } from "react";
import { ellTx } from "src/helpers";
import { useMobile } from "src/hooks";


export const StakeHistory = ({ address,refreshing }) => {
  const [history, setHistory] = useState([]);
  const { isSmallScreen } = useMobile();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading,setLoading] = useState(false);

  const loadPage = () => {
    console.log('[debug]refreshing....');
    setLoading(true);
    axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/user/${address}/stake`, { page,pageSize:8 }).then(ret => {
      if (ret.data.length == 0) setHasMore(false);
      else {
        setHistory([...history, ...ret.data])
        setPage(page + 1);
      }
    }).finally(()=>{
      setTimeout(()=>{
        setLoading(false);
      },1500)
    })
  }
  useEffect(() => {
    if (address&&(history.length<=0||refreshing)) {
      setHistory([]);
      setPage(0);
      loadPage();
    }
  }, [address,refreshing])

  if(loading){
    return (
      <Box display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ width: "100%", mb: 5 }}
    // ref={scrollTarget}
    >
      <CircularProgress></CircularProgress>
      </Box>
    )
  }

  return (
    <Box display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ width: "100%", mb: 5 }}
    // ref={scrollTarget}
    >
      <Paper className={`ohm-card token-card linear-card`} sx={{}}>
        <Box
          sx={{
            borderRadius: isSmallScreen ? "12px" : "24px",
            // border: "2px solid #434952",
            // background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            px: isSmallScreen ? 2.5 : 3.75,
            py: isSmallScreen ? 1.875 : 2.5,
          }}
        >
          <Typography sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "21px",
            fontStyle: "normal",
            fontWeight: "700",
          }}>
            <Trans>Stake History</Trans>
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="originLabel">
                  <Trans>Datetime</Trans>
                  </Typography>
                  
                </TableCell>
                <TableCell>
                <Typography variant="originLabel">
                  <Trans>TX</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                <Typography variant="originLabel">
                  <Trans>Amount</Trans>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                history.map((h, idx) => {
                  return (
                    <TableRow key={'srk_' + idx}>
                      <TableCell padding="none">
                        <Typography variant="originLabel">
                          {dayjs(h.createdAt).format('MM-DD HH:mm')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="originLabel">
                          <a className="label-link" href={`${process.env.REACT_APP_PUBLIC_SCAN}/${h.tx}`} target="_blank">{ellTx(h.tx)}</a>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="origin">
                          {Number(h.amount).toFixed(4)}
                        </Typography>
                      </TableCell>

                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>


          {
            hasMore ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => {
                loadPage();
              }}>
                <Typography variant="origin">
                  <Trans>
                    Load more
                  </Trans>
                </Typography>
              </Button>

            </Box>
              : <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="origin" >
                  <Trans>No more</Trans>
                </Typography>
              </Box>
          }
        </Box>
      </Paper>

    </Box>
  )
}