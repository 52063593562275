import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import OperatingBg from "../../assets/images/operatingBg.png";
import OperatingBgMobile from "../../assets/images/operatingBgMobile.png";

import Operating from "../../assets/images/en/EN4.png";
import OperatingZH from "../../assets/images/zh/CN4.webp";
import OperatingVN from "../../assets/images/vn/VNM4.png";
import OperatingKO from "../../assets/images/ko/KOR4.png";
import OperatingID from "../../assets/images/id/4.png";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { useMobile } from "../../hooks";

const images = {
  zh: OperatingZH,
  en: Operating,
  ko: OperatingKO,
  vi: OperatingVN,
  id: OperatingID,
  es: Operating,
  ja: Operating,
  ar: Operating,
  de: Operating,
  fr: Operating,
  ru: Operating,
  it: Operating,
  hi: Operating,
};

const HomeRules = () => {
  const { isSmallScreen } = useMobile();
  return (
    <Box sx={{ mb: isSmallScreen ? 6.25 : 12.5 }}>
      <Typography
        sx={{
          color: "#C7C8CC",
          fontFamily: "Neue Haas Grotesk Text Pro",
          fontSize: isSmallScreen ? "24px" : "54px",
          fontStyle: "normal",
          fontWeight: "700",
          mb: isSmallScreen ? 2.5 : 7.5,
        }}
        align="center"
      >
        <Trans>ORIGIN internal operating mechanism</Trans>
      </Typography>
      <Paper className="linear-card">
        <Box
          sx={{
            border: isSmallScreen ? "1px solid #434952" : "2px solid $434952",
            borderRadius: isSmallScreen ? "12px" : "24px",
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            position: "relative",
            width: "100%",
            height: isSmallScreen ? "auto" : "730px",
            px: isSmallScreen ? "10px" : "38px",
            py: isSmallScreen ? "15px" : "38px",
            overflow: "hidden",
            "&>img": {
              width: "100%",
              position: "relative",
              zIndex: "2",
            },
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={(images as any)[i18n.locale]} />
          <Box
            sx={{
              position: "absolute",
              bottom: isSmallScreen ? "-30px" : "0px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "120%",
              height: isSmallScreen ? "121px" : "450px",
              zIndex: "1",
              background: `url(${isSmallScreen ? OperatingBgMobile : OperatingBg}) no-repeat center center`,
              backgroundSize: "cover",
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default HomeRules;
