import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import ierc20ABIJson from "../abi/IERC20.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";
import StakingHelperABIJson from "../abi/StakingHelper.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError, IBaseAddressAsyncThunk } from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";

import { t } from "@lingui/macro";
import { NetworkID } from "src/lib/Bond";
import { setAll } from "src/helpers";
const ierc20ABI = ierc20ABIJson.abi;
const OlympusStakingABI = OlympusStakingABIJson.abi;
const StakingHelperABI = StakingHelperABIJson.abi;



// export const cliamWarmupBalance = createAsyncThunk(
export const legacyStatus = createAsyncThunk(
  "wallet/legacyStatus",
  async ({ provider, address, networkID }: IBaseAddressAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    return {
      provider,
      address,
      networkID
    }
    // const signer = provider.getSigner();

  },
);

export interface ILegacyStatus {
  provider: any
  address: string | null,
  networkID: NetworkID | null,
  error:any
}


const initialState: ILegacyStatus = {
  provider: null,
  address: "",
  networkID: null,
  error:null
};

const legacySlice = createSlice({
  name: "legacy",
  initialState,
  reducers: {
    legacyConnect(state: any, action: { payload: any }) {
    //   console.log('[debug]legacy connect worked:', action.payload, state);
      setAll(state, action.payload);
      if(action.payload.chainID!=137){
        state.error='Wrong network';
      }
    //   console.log('[debug][redux]legacy:',state)
    },
    legacyDisconnect(state:any,action:{payload:any}){
      setAll(state,initialState)
      console.log(state);
    }
  }
})

export const { legacyConnect, legacyDisconnect } = legacySlice.actions;

export default legacySlice.reducer;