import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import StakeExAbi from "../abi/stakeex.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { setAll } from "../helpers";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import ierc20Json from "../abi/IERC20.json";
import NFTShardAbi from '../abi/NFTShard.json';
import ArtisanAbi from '../abi/NFTArtisan.json';
import { error, info } from "./MessagesSlice";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { trim } from "../helpers";
import { useAccount } from "wagmi";
import { t } from "@lingui/macro";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { loadAppDetails } from "./AppSlice";
import { formatUnits } from "@ethersproject/units";
import { Web3Provider } from "@ethersproject/providers";

const ierc20ABI = ierc20Json.abi;

export const getStakeExInfo = createAsyncThunk(
    "stakeex/getStakeExInfo",
    async ({ address, networkID, provider }: any) => {
        
        try {
            const stakeExContract = new ethers.Contract(
                addresses[networkID].STAKING_EX_ADDRESS as string,
                StakeExAbi,
                provider,
            ) as any;

            const unitCost = await stakeExContract.unitCost();
            const period = await stakeExContract.period();
            const available = await stakeExContract.available();
            const stock = await stakeExContract.stock();

            return {
                unitCost: Number(unitCost),
                stock,
                available: Number(available),
                period: Number(period),
                allowanceDAI: 0,
                // turbineBal: ethers.utils.formatUnits(turbineBal, "9"),
                stakeList: [],
            };
        } catch (error) {
            console.log("[DEBUG]stakex error", error);
        }
    },
)

export const getStakeExData = createAsyncThunk(
    "stakeex/getStakeExData",
    async ({ address, networkID, provider }: any) => {
        try {
            const stakeExContract = new ethers.Contract(
                addresses[networkID].STAKING_EX_ADDRESS as string,
                StakeExAbi,
                provider,
            ) as any;
            const daiContract = new ethers.Contract(
                addresses[networkID].DAI_ADDRESS as string,
                ierc20ABI,
                provider,
            ) as IERC20;
            const unitCost = await stakeExContract.unitCost();
            const period = await stakeExContract.period();
            const available = await stakeExContract.available();
            const stock = await stakeExContract.stock();
            const stakeExCount = await stakeExContract.getStakesCount(address);


            const allowanceDAI = await daiContract.allowance(address, addresses[networkID].STAKING_EX_ADDRESS);

            let claimList = [];
            if (stakeExCount > 0) {
                const works = [];
                for (let i = 0; i < stakeExCount; i++) {
                    works.push(stakeExContract.getStakeInfo(address, i).then((ret: any) => {
                        return {
                            quantity: ret.quantity,
                            deposit: formatUnits(ret.deposit, 9),
                            expiry: Number(ret.expiry),
                            id: i
                        }
                    }));
                }
                claimList = await Promise.all(
                    works
                );
            }

            const shardContract = new ethers.Contract(
                addresses[networkID].WEBKEY_NFT_SHARD_ADDRESS as string,
                NFTShardAbi,
                provider,
            ) as any;
            const approved = await shardContract.isApprovedForAll(address, addresses[networkID].WEBKEY_NFT_ARTISAN_ADDRESS);
            // console.log('[debug]approved',approved);

            return {
                unitCost: Number(unitCost),
                stock,
                available: Number(available),
                period: Number(period),
                allowanceDAI: ethers.utils.formatUnits(allowanceDAI, "18"),
                // turbineBal: ethers.utils.formatUnits(turbineBal, "9"),
                stakeList: claimList,
                approved,
            };
        } catch (error) {
            console.log("[DEBUG]stakex error", error);
        }
    },
);

export const exStakeApprove = createAsyncThunk(
    "stakeex/exStakeApprove",
    async ({ provider, networkID, referer, quantity }: any, { dispatch }: any) => {
        if (!provider) {
            dispatch(error(t`Please connect your wallet!`));
            return;
        }
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        const daiContract = new ethers.Contract(
            addresses[networkID].DAI_ADDRESS as string,
            ierc20ABI,
            provider,
        ) as IERC20;
        let tx;
        try {
            const estimateGas = await daiContract.estimateGas.approve(
                addresses[networkID].STAKING_EX_ADDRESS,
                ethers.utils.parseUnits("1000000000", "18").toString(),
            );

            tx = await daiContract.approve(
                addresses[networkID].STAKING_EX_ADDRESS,
                ethers.utils.parseUnits("1000000000", "18").toString(),
                {
                    gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
                },
            );
            const text = "StakeExApproving";
            const pendingTxnType = "StakeExApproving";
            if (tx) {
                dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
                await tx.wait();
                return;
            }
        } catch (e: unknown) {
            // dispatch(error((e as any).message));
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            } else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            return;
        } finally {
            if (tx) {
                dispatch(clearPendingTxn(tx.hash));
            }
        }
    },
);


export const exStake = createAsyncThunk(
    "stakeex/exStake",
    async ({ provider, networkID, referer, quantity }: any, { dispatch }: any) => {
        if (!provider) {
            dispatch(error(t`Please connect your wallet!`));
            return;
        }
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        const stakeExContract = new ethers.Contract(
            addresses[networkID].STAKING_EX_ADDRESS as string,
            StakeExAbi,
            provider,
        ) as any;
        let tx;
        try {
            const estimateGas = await stakeExContract.estimateGas.stakeDAI(quantity);
            tx = await stakeExContract.stakeDAI(quantity, {
                gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
            });
            const text = "ExStaking";
            const pendingTxnType = "ExStaking";
            if (tx) {
                dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
                await tx.wait();
                return;
            }
        } catch (e: unknown) {
            // dispatch(error((e as any).message));
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            } else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            return;
        } finally {
            if (tx) {
                dispatch(clearPendingTxn(tx.hash));
            }
        }
    },
);


export const exStakeRedeem = createAsyncThunk(
    "stakeex/exStakeRedeem",
    async ({ provider, networkID, id }: any, { dispatch }: any) => {
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        const stakeExContract = new ethers.Contract(
            addresses[networkID].STAKING_EX_ADDRESS as string,
            StakeExAbi,
            provider,
        ) as any;
        const tx = await stakeExContract.redeem(id);
        await tx.wait();
        return tx;
    },
);

export const getOwnedNFT = createAsyncThunk(
    "stakeex/getOwnedNFT",
    async ({ address, provider, networkID }:any,dispatch:any)=>{
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        const ORIGIN_WEBKEY_NFT = new ethers.Contract(
            addresses[networkID].WEBKEY_NFT_ADDRESS as string,
            NFTShardAbi,
            provider,
        ) as any;

        const ownedNFT = await ORIGIN_WEBKEY_NFT.balanceOf(address);
        let nfts = [];
        for(let i=0;i<ownedNFT;i++){
            const tokenId = await ORIGIN_WEBKEY_NFT.tokenOfOwnerByIndex(address,i);
            nfts.push(tokenId);
        }   
        return {nfts};
    }
)

export const getOwnedNFTShard = createAsyncThunk(
    "stakeex/getOwnedNFTShard",
    async ({ address, provider, networkID }: any, dispatch: any) => {
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        const ORIGIN_WEBKEY_NFT_SHARD = new ethers.Contract(
            addresses[networkID].WEBKEY_NFT_SHARD_ADDRESS as string,
            NFTShardAbi,
            provider,
        ) as any;

        const ownedNFTShard = await ORIGIN_WEBKEY_NFT_SHARD.balanceOf(address);
        let nftShards = [];
        for (let i = 0; i < ownedNFTShard; i++) {
            const tokenId = await ORIGIN_WEBKEY_NFT_SHARD.tokenOfOwnerByIndex(address, i);
            nftShards.push(tokenId);
        }
        // for(let i=0;i<20;i++){
        //     nftShards.push(i)
        // }   
        return { nftShards };
    }
)

export const claimNFTShard = createAsyncThunk(
    "stakeex/claimNFTShard",
    async ({ provider, networkID, address,deadline, tx,signature }: any, { dispatch }: any) => {
        console.log('[debug]claim',address,deadline, tx,signature);
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        try{
            const ORIGIN_WEBKEY_NFT_SHARD = new ethers.Contract(
                addresses[networkID].WEBKEY_NFT_SHARD_ADDRESS as string,
                NFTShardAbi,
                provider,
            ) as any;
            const operator = await ORIGIN_WEBKEY_NFT_SHARD.operator();
            console.log('[debug]operator',operator);
            const txClaim = await ORIGIN_WEBKEY_NFT_SHARD.claim(deadline,tx,signature);
            await txClaim.wait();
            return txClaim;
        }catch(e:any){
            console.log(e);
            dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
        }
    },
);

export const forgeNFT = createAsyncThunk(
    "stakeex/forgeNFT",
    async ({ provider, networkID, address, shards }: any, { dispatch }: any) => {
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        const ARTISAN = new ethers.Contract(
            addresses[networkID].WEBKEY_NFT_ARTISAN_ADDRESS as string,
            ArtisanAbi,
            provider,
        ) as any;
        const txForge = await ARTISAN.forge(shards);
        await txForge.wait();
        return txForge;
    }
)

export const approveArtisan = createAsyncThunk(
    "stakeex/approveArtisan",
    async ({ provider, networkID, address, shards,approve=true }: any, { dispatch }: any) => {
        console.log('[debug]approveArtisan',address,shards,approve);
        provider = await provider instanceof Web3Provider?provider.getSigner():provider;
        const shardContract = new ethers.Contract(
            addresses[networkID].WEBKEY_NFT_SHARD_ADDRESS as string,
            NFTShardAbi,
            provider,
        ) as any;
        const txApprove = await shardContract.setApprovalForAll(addresses[networkID].WEBKEY_NFT_ARTISAN_ADDRESS,approve);
        await txApprove.wait();
        return txApprove;
    }
)  



interface IStakeExSlice {
    loading: boolean;
    unitCost: number;
    period: number;
    available: number;
    stock: number;
    allowanceDAI: string;
    stakeList: any[];
    nftShards: any[];
    nfts: any[];
    approved: boolean;
}

const initialState: IStakeExSlice = {
    loading: false,
    unitCost: 0,
    period: 0,
    available: 0,
    stock: 0,
    allowanceDAI: '0',
    stakeList: [],
    nftShards: [],
    nfts: [],
    approved: false,
};

const stakeExSlice = createSlice({
    name: "stakeex",
    initialState,
    reducers: {
        fetchTurbineSuccess(state: any, action: { payload: any }) {
            setAll(state, action.payload);
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getStakeExData.pending, (state: { loading: boolean }) => {
                state.loading = true;
            })
            .addCase(getStakeExData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getOwnedNFT.pending, (state: { loading: boolean }) => {
                state.loading = true;
            })
            .addCase(getOwnedNFT.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                setAll(state, action.payload);
                state.loading = false;
            }).addCase(getOwnedNFTShard.fulfilled,(state: { loading: boolean }, action: { payload: any }) => {
                setAll(state, action.payload);
                state.loading = false;
            }).addCase(getOwnedNFTShard.rejected,(state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            }).addCase(getOwnedNFTShard.pending,(state: { loading: boolean }, action: { payload: any }) => {
                state.loading = true;
            })
            .addCase(getStakeExData.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(getStakeExInfo.pending, (state: { loading: boolean }) => {
                state.loading = true;
            })
            .addCase(getStakeExInfo.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getStakeExInfo.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(claimNFTShard.pending, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = true;
            })
            .addCase(claimNFTShard.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = false;
            }).addCase(claimNFTShard.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            }).addCase(approveArtisan.pending, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = true;
            }).addCase(approveArtisan.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = false;
            }).addCase(approveArtisan.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            }).addCase(forgeNFT.pending, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = true;
            }).addCase(forgeNFT.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = false;
            }).addCase(forgeNFT.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            }).addCase(exStakeRedeem.pending, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = true;
            }).addCase(exStakeRedeem.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                state.loading = false;
            }).addCase(exStakeRedeem.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            })
            
    },
});


export default stakeExSlice.reducer;