import React, { useState, ReactElement, useContext, useEffect, useMemo, useCallback } from "react";
import Web3Modal from "web3modal";
import { StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { IFrameEthereumProvider } from "@ledgerhq/iframe-provider";
import { EnvHelper } from "../helpers/Environment";
import { NodeHelper } from "src/helpers/NodeHelper";
import { useAccount, useChainId, useWalletClient, usePublicClient, useConnect } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useDispatch, useSelector } from "react-redux";
import { NetworkID } from "src/lib/Bond";
import detectEthereumProvider from "@metamask/detect-provider";
import { legacyConnect, legacyDisconnect } from "src/slices/LegacySlice";
// useConnect
// import { useDispatch } from "react-redux";
// import { error } from "../slices/MessagesSlice";
// import { useEthersSigner } from "./wagmi";

interface ILegacyView {
  legacy: {
    provider: any,
    address: string,
    networkID: NetworkID,
    error:any
  }
}

export const DEFAULT_PROVIDER = new StaticJsonRpcProvider(process.env.REACT_APP_RPC_URL);

export const useWeb3Context = () => {
  
  const dispatch = useDispatch();
  
  const { address, provider, networkID, error } = useSelector((state: ILegacyView) => {
    return state.legacy
  })
  
  const connect = useCallback(() => {
    console.log('[debug]connect inejected provider')
    if (!window['ethereum']) {
      throw new Error('Please switch to Web3 V2');
    }
    else {
      let _provider: any, _address, _chainID: any;
      detectEthereumProvider({ timeout: 5000 }).then(provider => {

        _chainID = Number((provider as any).chainId);
        _provider = new Web3Provider(window.ethereum);
        return window.ethereum.request({ method: 'eth_requestAccounts' });

      }).then(addresses => {

        _address = addresses && addresses.length > 0 && addresses[0];
        dispatch(legacyConnect({ provider: _provider, address: _address, chainID: _chainID }));
        return _provider.getSigner();        
      }).catch(err => {
        console.error(err);
      })
      
    }
  }, []);

  const disconnect = useCallback(() => {
    dispatch(legacyDisconnect({}))
  }, [])

  return {
    address,
    chainID: networkID || 137,
    // chainID,
    provider: provider || DEFAULT_PROVIDER,
    
    disconnect,
    connected: !!address,
    error,
    connect,
  };
};

export const useAddress = () => {
  const { address } = useWeb3Context();
  return address;
};
