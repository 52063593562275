import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import TurbineJson from "../abi/Turbine.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { setAll } from "../helpers";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import ierc20Json from "../abi/IERC20.json";
import { error, info } from "./MessagesSlice";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { trim } from "../helpers";
import { useAccount } from "wagmi";
import { t } from "@lingui/macro";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { loadAppDetails } from "./AppSlice";
import { Web3Provider } from "@ethersproject/providers";


const TurbineAbi = TurbineJson.abi;
const ierc20ABI = ierc20Json.abi;

export const getTurbineData = createAsyncThunk(
  "turbine/getTurbineData",
  async ({ address, networkID, provider }: any) => {
    
    const turbineContract = new ethers.Contract(
      addresses[networkID].TURBINE_ADDRESS as string,
      TurbineAbi,
      provider,
    ) as any;

    try {
      console.log('[debug]turbine:',address,turbineContract);
      const turbineBal = await turbineContract.turbineBal(address);

      const claimInfo = await turbineContract.getClaimInfo(address);
      const turbineVestingTerm = await turbineContract.vestingTerm();
      
      let claimList = [];
      if (claimInfo.length > 0) {
        claimList = await Promise.all(
          claimInfo.map(async (claimItem: any) => {
            let turbineRedeemBlock = Number(turbineVestingTerm) + Number(claimItem.lastBlock);
            // console.log(turbineRedeemBlock, "turbineRedeemBlock", turbineVestingTerm);
            return {
              ...claimItem,
              rewardBalance: ethers.utils.formatUnits(claimItem.rewardBalance, "9"),
              silenceBalance: ethers.utils.formatUnits(claimItem.silenceBalance, "9"),
              turbineRedeemBlock,
            };
          }),
        );
      }
      claimList = claimList.filter((_: any) => _.silenceBalance > 0).reverse();
      
      return {
        turbineBal: ethers.utils.formatUnits(turbineBal, "9"),
        claimList,
      };
    } catch (error) {
      console.log("error", error);
    }
  },
);

export const syncSilenceAmount = async (amount:any,networkID:any,provider:any)=>{
    const turbineContract = new ethers.Contract(
        addresses[networkID].TURBINE_ADDRESS as string,
        TurbineAbi,
        provider,
      ) as any;
      console.log("amount", amount, ethers.utils.parseUnits(String(trim(amount, 9)), "9"));
      try {
        const silenceAmount = await turbineContract.getSilenceUSDTAmount(
          ethers.utils.parseUnits(String(trim(amount, 9)), "9"),
        );
        // const silenceAmount = await turbineContract.getSilenceUSDTAmount(String(ethers.utils.parseUnits(amount)));
        console.log("silenceAmount", silenceAmount);
        return {
          silenceAmount: ethers.utils.formatUnits(silenceAmount, "18"),
        };
      } catch (error) {
        console.log("error", error);
      }
}

export const getSilenceAmount = createAsyncThunk(
  "turbine/getSilenceAmount",
  async ({ amount, networkID, provider }: any) => {
    const turbineContract = new ethers.Contract(
      addresses[networkID].TURBINE_ADDRESS as string,
      TurbineAbi,
      provider,
    ) as any;
    console.log('[debug]amount:',amount);
    console.log("amount", amount, ethers.utils.parseUnits(String(trim(amount, 9)), "9"));
    try {
      const silenceAmount = await turbineContract.getSilenceUSDTAmount(
        ethers.utils.parseUnits(String(trim(amount, 9)), "9"),
      );
      // const silenceAmount = await turbineContract.getSilenceUSDTAmount(String(ethers.utils.parseUnits(amount)));
      console.log("silenceAmount", silenceAmount);
      return {
        silenceAmount: ethers.utils.formatUnits(silenceAmount, "18"),
      };
    } catch (error) {
      console.log("error", error);
    }
  },
);

export const approveTurbine = createAsyncThunk(
  "turbine/approveTurbine",
  async ({ provider, networkID, address, amount }: any, { dispatch }: any) => {
    // const { isConnected } = useAccount();
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
    // const signer = provider;
    const daiContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20ABI, signer) as IERC20;
    let approveTx;
    try {
      const approveAmount =
        Number(amount) > 100000
          ? ethers.utils.parseUnits(String(trim(amount, 18)), "18")
          : ethers.utils.parseUnits("100000");
      const estimateGas = await daiContract.estimateGas.approve(addresses[networkID].TURBINE_ADDRESS, approveAmount);

      approveTx = await daiContract.approve(addresses[networkID].TURBINE_ADDRESS, approveAmount, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_turbine";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        // dispatch(
        //   getBalances({
        //     address,
        //     provider,
        //     networkID,
        //   }),
        // );
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
    // const daiContract = new ethers.Contract(
    //   addresses[networkID].DAI_ADDRESS as string,
    //   ierc20ABI,
    //   provider,
    // ) as SOhmv2;

    const daiAllowanceInTurbine = await daiContract.allowance(address, addresses[networkID].TURBINE_ADDRESS);
    return dispatch(
      fetchAccountSuccess({
        daiAllowanceInTurbine,
      }),
    );
  },
);

export const turbineSilence = createAsyncThunk(
  "turbine/turbineSilence",
  async ({ provider, networkID, address, usdtAmount }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    const turbineSlippage = localStorage.getItem("turbineSlippage") || 0.1;
    const turbineDeadline = localStorage.getItem("turbineDeadline") || 20;
    // const slipperAmount = usdtAmount * (1 - Number(turbineSlippage) / 100);
    const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
    // const signer = provider;
    const turbineContract = new ethers.Contract(addresses[networkID].TURBINE_ADDRESS as string, TurbineAbi, signer);
    let silenceTx;
    // console.log("slipperAmount", usdtAmount);
    try {
      const estimateGas = await turbineContract.estimateGas.silence(
        address,
        ethers.utils.parseUnits(String(trim(usdtAmount, 18)), "18"),
        Date.now() + 1000 * 60 * Number(turbineDeadline),
      );

      // console.log("amount", ethers.utils.parseUnits(String(slipperAmount), "9"), slipperAmount);
      silenceTx = await turbineContract.silence(
        address,
        ethers.utils.parseUnits(String(trim(usdtAmount, 18)), "18"),
        // ethers.utils.parseUnits(String(silenceAmount), "9"),
        Date.now() + 1000 * 60 * Number(turbineDeadline),
        {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        },
      );
      // console.log("silenceTx", silenceTx);
      const text = "Silence";
      const pendingTxnType = "turbine_silence";
      if (silenceTx) {
        dispatch(fetchPendingTxns({ txnHash: silenceTx.hash, text, type: pendingTxnType }));
        await silenceTx.wait();
        dispatch(loadAppDetails({ networkID, provider }));
        return;
      }
    } catch (e: unknown) {
      console.log("error", e);
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (silenceTx) {
        dispatch(clearPendingTxn(silenceTx.hash));
      }
    }
  },
);

export const turbineRedeem = createAsyncThunk(
  "turbine/turbineRedeem",
  async ({ provider, networkID, address, id }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
    // const signer = provider;
    const turbineContract = new ethers.Contract(addresses[networkID].TURBINE_ADDRESS as string, TurbineAbi, signer);
    let silenceTx;
    try {
      const estimateGas = await turbineContract.estimateGas.redeemSilence(address, id);

      silenceTx = await turbineContract.redeemSilence(address, id, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      // console.log("silenceTx", silenceTx);
      const text = "Redeem";
      const pendingTxnType = "turbine_redeem";
      if (silenceTx) {
        dispatch(fetchPendingTxns({ txnHash: silenceTx.hash, text, type: pendingTxnType }));
        await silenceTx.wait();
        return;
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (silenceTx) {
        dispatch(clearPendingTxn(silenceTx.hash));
      }
    }
  },
);

interface ITurbineSlice {
  turbineBal: number;
  silenceAmount: number;
  claimList: any[];
}

const initialState: ITurbineSlice = {
  turbineBal: 0,
  silenceAmount: 0,
  claimList: [],
};

const turbineSlice = createSlice({
  name: "turbine",
  initialState,
  reducers: {
    fetchTurbineSuccess(state: any, action: { payload: any }) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getTurbineData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getTurbineData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getTurbineData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getSilenceAmount.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getSilenceAmount.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getSilenceAmount.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default turbineSlice.reducer;

export const { fetchTurbineSuccess } = turbineSlice.actions;

const baseInfo = (state: RootState) => state.turbine;

export const getTurbineState = createSelector(baseInfo, turbine => turbine);
