import { ethers, BigNumberish, BigNumber } from "ethers";
import { addresses, OHM_INITIAL_PRICE } from "../constants";
import OlympusStakingv2ABIJson from "../abi/OlympusStakingv2.json";
import FuseProxyABIJson from "../abi/FuseProxy.json";
import DistributorContractABIJson from "../abi/Distributor.json";
import sOHMv2Json from "../abi/sOhmv2.json";
import { setAll, getTokenPrice, getMarketPrice, trim } from "../helpers";
import apollo from "../lib/apolloClient";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { OlympusStakingv2, SOhmv2, FuseProxy, DistributorContract } from "../typechain";
import TurboLotteryAbiJson from "../abi/TurboLottery.json";
import LotteryAbiJson from "../abi/Lottery.json";
import { erc20ABI } from "wagmi";
import { Web3Provider } from "@ethersproject/providers";
import { providers } from '@0xsequence/multicall';

const OlympusStakingv2ABI = OlympusStakingv2ABIJson.abi;
const FuseProxyABI = FuseProxyABIJson.abi;
const DistributorContractABI = DistributorContractABIJson.abi;
const sOHMv2 = sOHMv2Json.abi;
const TurboLotteryAbi = TurboLotteryAbiJson.abi;
const LotteryAbi = LotteryAbiJson.abi;

interface IProtocolMetrics {
  readonly timestamp: string;
  readonly ohmCirculatingSupply: string;
  readonly sOhmCirculatingSupply: string;
  readonly totalSupply: string;
  readonly ohmPrice: string;
  readonly marketCap: string;
  readonly totalValueLocked: string;
  readonly treasuryMarketValue: string;
  readonly nextEpochRebase: string;
  readonly nextDistributedOhm: string;
}

export const getMintSupplyData = createAsyncThunk(
  "app/getMintSupplyData",
  async ({ networkID, provider }: any, { dispatch }) => {
    const tusdbContract = new ethers.Contract(addresses[networkID].TUSDB_ADDRESS as string, erc20ABI, provider) as any;

    const tusdTotalSupply = await tusdbContract.totalSupply();
    const tusdbDecimals = await tusdbContract.decimals();
    console.log("getMintSupplyData tusdTotalSupply", tusdTotalSupply, tusdbDecimals);
    return {
      tusdTotalSupply: ethers.utils.formatUnits(tusdTotalSupply, tusdbDecimals),
    } as unknown as IAppData;
  },
);

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: any, { dispatch }) => {
    const multiProvider = new providers.MulticallProvider(provider);
    const signerOrProvider = provider instanceof Web3Provider ? provider.getSigner() : provider;
    const protocolMetricsQuery = `
  query {
    _meta {
      block {
        number
      }
    }
    protocolMetrics(first: 1, orderBy: timestamp, orderDirection: desc) {
      timestamp
      ohmCirculatingSupply
      sOhmCirculatingSupply
      totalSupply
      ohmPrice
      marketCap
      totalValueLocked
      treasuryMarketValue
      nextEpochRebase
      nextDistributedOhm
    }
  }
`;
    const graphData = await apollo<{ protocolMetrics: IProtocolMetrics[] }>(protocolMetricsQuery);
    // console.log("loadAppDetails graphData", graphData);
    if (!graphData || graphData == null) {
      console.error("Returned a null response when querying TheGraph");
      return;
    }

    const stakingTVL =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.totalValueLocked)
        : 0;
    // NOTE (appleseed): marketPrice from Graph was delayed, so get CoinGecko price
    // const marketPrice = parseFloat(graphData.data.protocolMetrics[0].ohmPrice);
    let marketPrice;
    try {
      const originalPromiseResult = await dispatch(
        loadMarketPrice({ networkID: networkID, provider: provider }),
      ).unwrap();
      marketPrice = originalPromiseResult?.marketPrice;
    //   console.log("[debug]marketPrice loadAppDetails marketPrice", marketPrice);
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error("Returned a null response from dispatch(loadMarketPrice)");
      return;
    }
    // console.log('[debug]marketPrice:', marketPrice, "marketPrice");

    const marketCap =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.marketCap)
        : 0;
    const circSupply = graphData.data.protocolMetrics[0]
      ? parseFloat(graphData.data.protocolMetrics[0]?.ohmCirculatingSupply)
      : 0;
    const totalSupply =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.totalSupply)
        : 0;
    const treasuryMarketValue =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.treasuryMarketValue)
        : 0;
    // const currentBlock = parseFloat(graphData.data._meta.block.number);
    // const signer = provider.getSigner();
    // console.log(provider, "provider", signer);

    if (!provider) {
      console.error("failed to connect to provider, please connect your wallet");
      return {
        stakingTVL,
        marketPrice,
        marketCap,
        circSupply,
        totalSupply,
        treasuryMarketValue,
      } as IAppData;
    }
    // console.log("loadAppDetails provider", provider);
    const currentBlock = await provider.getBlockNumber();
    // console.log("loadAppDetails currentBlock", currentBlock);
    const tusdbContract = new ethers.Contract(addresses[networkID].TUSDB_ADDRESS as string, erc20ABI, multiProvider) as any;
    // console.log("loadAppDetails currentBlock", currentBlock);
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingv2ABI,
      multiProvider,
    ) as OlympusStakingv2;
    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS as string,
      FuseProxyABI,
      multiProvider,
    ) as FuseProxy;
    const stakingDistributorContract = new ethers.Contract(
      addresses[networkID].DISTRIBUTOR_ADDRESS as string,
      DistributorContractABI,
      multiProvider,
    ) as any;
    const sohmMainContract = new ethers.Contract(
      addresses[networkID].SOHM_ADDRESS as string,
      sOHMv2,
      multiProvider,
    ) as SOhmv2;
    // console.log("loadAppDetails currentBlock", currentBlock);

    // const turboLotteryContract = new ethers.Contract(
    //   addresses[networkID].TURBO_LOTTERY_ADDRESS as string,
    //   TurboLotteryAbi,
    //   provider,
    // );

    // const totalReserves = await 
    // const turboCurrentReward = await turboLotteryContract.currentTurnAccumulatedRewardsOfLottery();
    // const fomoCurrentReward = await fomoLotteryContract.currentTurnAccumulatedRewardsOfLottery();
    // console.log(
    //   "loadAppDetails currentBlock",
    //   currentBlock,
    //   stakingDistributorContract,
    //   stakingDistributorContract.address,
    // );

    // console.log("fomoCurrentReward", fomoCurrentReward);
    // console.log("turboCurrentReward", turboCurrentReward);
    // console.log("bondMaturationBlock currentBlock 2", currentBlock);
    // info params 0 for testnet 1 for bscmainnet
    // const rate = await stakingDistributorContract.info(0);
    // const param = location.hostname == "app.xenophon.finance" ? 1 : 0;
    // let rate: any = [];
    // try {
    //   rate = await stakingDistributorContract.info(0);

    //   console.log("loadAppDetails rate 3", rate);
    // } catch (error) {
    //   console.log("loadAppDetails rate error", error);
    // }

    // console.log("totalReserves", totalReserves, "rate", rate, rate[0], stakingDistributorContract);
    
    
    const [totalReserves,rate,epoch, tokenBalInStaking, circ, currentIndex, tusdTotalSupply, tusdbDecimals] = await Promise.all([
      treasuryContract.totalReserves(),
      stakingDistributorContract.info(0),
      stakingContract.epoch(),
      stakingContract.contractBalance(),
      sohmMainContract.circulatingSupply(),
      stakingContract.index(),
      tusdbContract.totalSupply(),
      tusdbContract.decimals(),
    ]);

    const StakeRate = rate[0];
    // Calculating staking
    // const epoch = await stakingContract.epoch();
    // const tokenBalInStaking = await stakingContract.contractBalance();
    // const circ = await sohmMainContract.circulatingSupply();
    // const currentIndex = await stakingContract.index();
    // const tusdTotalSupply = await tusdbContract.totalSupply();
    // const tusdbDecimals = await tusdbContract.decimals();


    // console.log("loadAppDetails epoch", epoch);

    const endBlock = epoch.endBlock;
    // console.log("tokenBalInStaking", tokenBalInStaking);

    const stakingReward = epoch.distribute;
    // console.log("circ", circ.toString(), stakingReward.toString());
    const stakingRebase = Number(stakingReward.toString()) / Number(circ.toString());
    // console.log("stakingRebase", stakingRebase.toString());

    const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
    const stakingAPY = Math.pow(1 + stakingRebase, 365 * 3) - 1;
    // console.log('stakingAPY:', stakingAPY);

    // Math.LOG10E;
    // const data = Math.LN10(a1);
    // console.log("data", data);
    // const runWay = Math.log(treasuryReBalance / StakeBalance) / Math.log(1 + StakeRate / Math.pow(10, 6)) / 3;
    // console.log("[debug]marketPrice", marketPrice);
    const marketValue = marketPrice * totalSupply;
    // console.log("market", marketValue, marketPrice);

    const _marketPrice = BigNumber.from(String(Number(Math.floor(marketPrice) * 1e9)));
    // console.log("_marketPrice", _marketPrice);
    // const times = BigNumber.from(1)
    let times = Number(OHM_INITIAL_PRICE.div(_marketPrice));
    // console.log("aae times", times);
    let priceDown = true;
    if (_marketPrice.gt(OHM_INITIAL_PRICE)) {
      priceDown = false;
      times = Number(_marketPrice.div(OHM_INITIAL_PRICE));
    }
    // console.log("market app priceDown", priceDown, times);
    const stakeMinAmount = priceDown
      ? BigNumber.from(100000000000).mul(times)
      : BigNumber.from(100000000000).div(times);

    // console.log("tusdTotalSupply", tusdTotalSupply, tusdbDecimals);
    return {
      endBlock,
      currentIndex: ethers.utils.formatUnits(currentIndex, "gwei"),
      tokenBalInStaking: ethers.utils.formatUnits(tokenBalInStaking, "9"),
      // ohmBalInDaoAddress: ethers.utils.formatUnits(ohmBalInDaoAddress, "9"),
      totalReserves: ethers.utils.formatUnits(totalReserves, "9"),
      currentBlock,
      fiveDayRate,
      stakingAPY,
      stakingTVL,
      stakingRebase,
      marketCap,
      marketPrice,
      circSupply,
      totalSupply,
      treasuryMarketValue,
      marketValue,
      StakeRate,
      // turboCurrentReward: ethers.utils.formatUnits(turboCurrentReward, "18"),
      // fomoCurrentReward: ethers.utils.formatUnits(fomoCurrentReward, "18"),
      stakeMinAmount: ethers.utils.formatUnits(stakeMinAmount, 18),
      tusdTotalSupply: ethers.utils.formatUnits(tusdTotalSupply, tusdbDecimals),
    } as unknown as IAppData;
  },
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

/**
 * - fetches the LGNS price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from ohm-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    // console.log("marketPrice", marketPrice);
    marketPrice = marketPrice / Math.pow(10, 9);
  } catch (e) {
    console.error("Error fetching market price", e);
    marketPrice = 0;
  }
  // console.log("marketPrice", marketPrice);

  return { marketPrice };
});

interface IAppData {
  readonly circSupply?: number;
  readonly currentIndex?: string;
  readonly currentBlock?: number;
  readonly fiveDayRate?: number;
  readonly loading: boolean;
  readonly loadingMarketPrice: boolean;
  readonly marketCap?: number;
  readonly marketPrice?: number;
  readonly stakingAPY?: number;
  readonly stakingRebase?: number;
  readonly stakingTVL?: number;
  readonly totalSupply?: number;
  readonly treasuryBalance?: number;
  readonly treasuryMarketValue?: number;
  readonly marketValue?: number;
  readonly endBlock1?: number;
  readonly tokenBalInStaking?: number;
  readonly StakeRate?: number;
  readonly totalReserves?: number;
  readonly turboCurrentReward?: number;
  readonly fomoCurrentReward?: number;
  readonly stakeMinAmount?: string;
  readonly tusdTotalSupply?: number;
}

const initialState: IAppData = {
  loading: false,
  loadingMarketPrice: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(getMintSupplyData.pending, state => {
        state.loading = true;
      })
      .addCase(getMintSupplyData.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getMintSupplyData.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error.name, error.message, error.stack);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
